import React, { useState, StrictMode, useMemo, useEffect } from "react";
import ReactDOM from "react-dom";
import { createRoot } from "react-dom/client";
import axios from "axios";
import SideNav from "./SalesToolComponents/SideNav";
import ProductSelection from "./SalesToolComponents/ProductSelection";
import { CaretRight, CheckCircle, Eye, ShoppingBag } from "phosphor-react";
import ClientSelection from "./SalesToolComponents/ClientSelection";
import Finish from "./SalesToolComponents/Finish";
import BasicInformation from "./SalesToolComponents/BasicInformation";
import VariantTabs from "./SalesToolComponents/components/VariantTabs";
import FinishTabs from "./SalesToolComponents/components/FinishTabs";
import Loader from "./SalesToolComponents/components/Loader";

export default function SalesTool() {
    const [clientStep, setClientStep] = useState(true);
    const [informationStep, setInformationStep] = useState(false);
    const [invoiceStep, setInvoiceStep] = useState(false);
    const [finishStep, setFinishStep] = useState(false);

    const [tabs, setTab] = useState([{ name: "Variant name", selected: true }]);

    const [stepNr, setStepNr] = useState(1);
 
    const [client, setClient] = useState(null);
    const [order, setOrder] = useState(null);
    const [basicInformation, setBasicInformation] = useState(() => [
        {
            stairs_description: null,
            type: null,
            model: null,
            material: null,
            color_step: null,
            color_riser: null,
            exit_step: null,
            transfer_step: null,
            cheeks: 'nee',
            color_cheeks: null,
            color_l_u_profile: null,
            extra_description: null,
            selectedMaterial: null,
            transfer_steps: [{}],
            numbers: [],
            extra_stairs: []
        }
    ]);

    const [products, setProducts] = useState(null);
    const [planning, setPlanning] = useState(null);
    const [afterSales, setAfterSales] = useState(null);

    const [selectedCategory, setSelectedCategory] = useState(null);
    // const [categories, setCategories] = useState(null);
    const [cart, setCart] = useState([]);

    const [quotationSaving,setQuotationSaving] = useState(false)

    const [search,setSearch] = useState(false);
    // const [searchResults,setSearchResults] = useState(false);

    const [showCart, setShowCart] = useState(false);
    const [showProducts, setShowProducts] = useState(false);

    const [materials, setMaterials] = useState([]);
    const [labors, setLabors] = useState([]);
    const [accessoires, setAccessoires] = useState([]);

    // const getProducts = (cat) => {
    //     axios.get("/sales/get-products/" + cat).then((response) => {
    //         console.log(response);
    //         setProducts(response.data);
    //         if (!showProducts) {
    //             setShowProducts(true);
    //         }
    //         console.log("proddd", products);
    //     });
    // };




    const saveQuotation = () => {
        setQuotationSaving(true)
        console.log('save!!!',basicInformation)
        axios.post('/sales/save',{
            order: order,
            basicInformation: basicInformation,
            tabs: tabs,
            client: client,
        }).then((response) => {
            console.log('response',response)     
            setTimeout(() => {
                setQuotationSaving(false)
            },1500)
        })
    }

    // const addToCart = (item) => {
    //     setCart((prev) => [item, ...prev]);
    //     console.log(item, cart);
    // };

    // const setProductSearch = (o) => {
    //     console.log(o,search)
    //     if(o == ''){
    //         setSearch(false)
    //     } else {
    //         setSearch(true)
    //         axios.get("/sales/search-products/" + o).then((response) => {
    //             console.log(response);                
    //             console.log("proddd", products);
    //             setSearchResults(response.data)
    //         });
    //     }
    // }

    const goToStep = (type) => {
        console.log("go to stepp");
        if (type == "client") {
            setClientStep(true);
            setFinishStep(false);
            setInvoiceStep(false);
            setInformationStep(false);
            setStepNr(1)
        }
        if(type == "information"){
            if(client !== null && client.hasOwnProperty('customer')) {
                setClientStep(false);
                setFinishStep(false);
                setInformationStep(true);
                setInvoiceStep(false);
                setStepNr(2)
            } else {
                alert('Selecteer eerst een klant!')
                console.log(client)
            }
            
        }
        if(type == "calculations"){
         
            setClientStep(false);
            setFinishStep(false);
            setInvoiceStep(true);
            setInformationStep(false);
            setStepNr(3)
        }
        if(type == "finish"){
            if(client.hasOwnProperty('customer') && basicInformation) {
            setClientStep(false);
            setInvoiceStep(false);
            setInformationStep(false);
            setFinishStep(true);
            setStepNr(4)
            }
        }
    };

    const fetchCustomerCare = async () => {
        try {
          const customerCareResponse = await axios.get("/sales/customer-care");
          setAfterSales(customerCareResponse.data);
        } catch (error) {
          console.error("Error fetching customer care data", error);
        }
      };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get("/sales/get-sales-data");
                console.log(response);
                const data = response.data;
        
                setPlanning(data.planning);
                setMaterials(data.materials);
                setLabors(data.labors);
                setAccessoires(data.accessoires);
        
                console.log("cattt", planning); 

            } catch (error) {
                console.error("Error fetching data", error);
            }
        };
        fetchCustomerCare();
        fetchData();

    }, []);

    return (
        <>
            {planning == null ? (
                <Loader/>
            ) : (
                <>
                    <nav className="sales-step-nav">
                        <div
                            className={
                                "sales-step" + (stepNr >= 1 ? " active" : "")
                            }
                            onClick={() => goToStep("client")}
                        >
                            <p className="circle">
                                { stepNr > 1 ?
                                  <CheckCircle weight={'bold'} size={18} style={{position:'relative',top:'-1px'}}/>
                                    :
                                    <span>1</span>
                                }
                            </p>
                            <p>Selecteer klant</p>
                        </div>
                        <div
                            className={
                                "sales-step" + (stepNr >= 2  ? " active" : "")
                            }
                            onClick={() => goToStep("information")}
                        >
                            <p className="circle">
                            { stepNr > 2 ?
                                  <CheckCircle weight={'bold'} size={18} style={{position:'relative',top:'-1px'}}/>
                                  :
                                    <span>2</span>
                                }
                            </p>
                            <p>Basis informatie</p>
                        </div>                  
                        <div
                            className={
                                "sales-step" + (stepNr >= 4  ? " active" : "")
                            }
                            onClick={() => goToStep("finish")}
                        >
                            <p className="circle">3</p>
                            <p>Afronden offerte</p>
                        </div>
                    </nav>
                    {/* {invoiceStep && (
                        <button
                            onClick={() => setShowCart(!showCart)}
                            className="btn cart-toggle"
                        >
                            <ShoppingBag size={36} />
                            <span className="badge">{cart.length}</span>
                        </button>
                    )} */}
                    {/* <button onClick={() => setShowProducts(!showProducts)}>
                        Toggle products
                    </button> */}
<React.StrictMode>
                    <div className="steps-wrapper">
                        <div
                            className={
                                "step-wrapper client-step" +
                                (clientStep ? " active" : "")
                            }
                        >
                            <ClientSelection
                                client={client}
                                setClient={setClient}
                                planning={planning}                                
                                goToStep={goToStep}
                                setBasicInformation={setBasicInformation}
                                order={order}
                                setOrder={setOrder}
                                setTab={setTab}
                                afterSales={afterSales}
                                fetchCustomerCare={fetchCustomerCare}
                            />
                        </div>

                        <div
                            className={
                                "step-wrapper information-step" +
                                (informationStep ? " active" : "")  
                            }
                        >
                            <VariantTabs
                            goToStep={goToStep}
                            setBasicInformation={setBasicInformation}
                            basicInformation={basicInformation}
                            tabs={tabs}
                            setTab={setTab}
                            materials={materials}
                            labors={labors}
                            accessoires={accessoires}
                            client={client}
                            />                            
                        </div>            
                        <div className={
                                "step-wrapper finish-step" +
                                (finishStep ? " active" : "")
                            }>

                               { finishStep &&
                                 <FinishTabs                                  
                                 order={order}
                                 goToStep={goToStep}
                                 finishStep={finishStep}
                                 basicInformation={basicInformation}
                                 client={client}
                                 setBasicInformation={setBasicInformation}
                                 setClient={setClient}
                                 tabs={tabs}
                                 setTab={setTab}
                                 saveQuotation={saveQuotation}
                                 quotationSaving={quotationSaving}                              
                                />
                               }

                                
                        </div>
                    </div>
                    </React.StrictMode>
                </>
            )}

            {/* <SideNav
                cart={cart}
                showCart={showCart}
                setShowCart={setShowCart}
                order={order}
                setOrder={setOrder}
                client={client}
                goToStep={goToStep}
            />
            
            <ProductSelection
                showProducts={showProducts}
                setShowProducts={setShowProducts}
                products={products}
                selectedCategory={selectedCategory}
                addToCart={addToCart}
            /> */}

        </>
    );
}

const rootElement = document.getElementById("sales_tool");
if(rootElement){
    const root = createRoot(rootElement);

root.render(<SalesTool />);

}