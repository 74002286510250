import { PhoneCall } from "phosphor-react";
import { useState } from "react";
import axios from "axios";
import CustomerCareQuotationData from "./CustomerCareQuotationData";
import CustomerCareFormModal from "./CustomerCareFormModal";
import Loader from "./components/Loader";
import { Check, MapPin, MarkerCircle, Phone, UserCircle } from "@phosphor-icons/react";

const CustomerCare = (props) => {
    const { o, fetchCustomerCare } = props;

    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [quotationData, setQuotationData] = useState(null);

    const [modalCustomerCareIsOpen, setModalCustomerCareIsOpen] = useState(false);

    const getQuotationData = () => {
        setModalIsOpen(true);
        axios.get(`/sales/get-quotation/${o.planning.latest_afgerond_quotation.id}`)
            .then((res) => {
                setQuotationData(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    const closeModal = () => {
        setModalIsOpen(false);
        setQuotationData(null);
    }

    const openFormModal = () => {
        setModalCustomerCareIsOpen(true);
    }

    const closeFormModal = () => {
        setModalCustomerCareIsOpen(false);
    }

    return (
        <div className="card rounded-2 my-2 customer-card">
            <div className="card-body d-flex">
                <div className="w-100">
                    <p style={{fontSize:'16px', margin:0}}>
                        {o.planning.customer.firstname}{" "}
                        {o.planning.customer.lastname}
                    </p>
                    <p className="mb-0 d-flex w-100 align-items-center gap-2"><MapPin fill={'#aaa'} size={16}/>{o.planning.customer.street} {o.planning.customer.number}{o.planning.customer.addition}, {o.planning.customer.zipcode}, {o.planning.customer.place}</p>
                    <p style={{fontSize:'18px', fontWeight:700}} className="mb-1"> <Phone size={18}/> {o.planning.customer.phone}</p>
                  
                 
                        <label style={{fontWeight:600}}>Beschrijving</label>
                        <p className="m-0">{o.start_description}</p>
                  
                    
                </div>
                <div className="d-flex flex-wrap align-items-center gap-3">
                    <button type="button" onClick={getQuotationData} className="btn btn-outline-primary w-100 mt-auto">Meer informatie</button>
                    <button type="button" onClick={openFormModal} className="btn btn-primary w-100 mb-auto d-flex align-items-center gap-2 justify-content-center"><Check size={18} />Afronden</button>
                </div>
            </div>

            {modalIsOpen && (
                <div className={`modal-overlay ${modalIsOpen ? 'show' : ''}`} onClick={closeModal}>
                    <div className={`modal-content ${modalIsOpen ? 'show' : ''}`} onClick={(e) => e.stopPropagation()}>
                        <button className="close-button" onClick={closeModal}>×</button>
                        {quotationData ? (
                            <div>                        
                                <h3>Klant gegevens</h3>                                    
                    <p className="mb-2 d-flex w-100 align-items-center gap-2" style={{fontSize:'18px', fontWeight:500}}> <UserCircle fill={'#aaa'} size={21}/> {o.planning.customer.firstname}{" "}
                    {o.planning.customer.lastname}</p>
                    <p className="mb-2 d-flex w-100 align-items-center gap-2" style={{fontSize:'18px', fontWeight:500}}> <Phone fill={'#aaa'} size={21}/> {o.planning.customer.phone}</p>
                    <p className="mb-3 d-flex w-100 align-items-center gap-2" style={{fontSize:'18px', fontWeight:500}}><MapPin fill={'#aaa'} size={21}/>{o.planning.customer.street} {o.planning.customer.number}{o.planning.customer.addition}, {o.planning.customer.zipcode}, {o.planning.customer.place}</p>
                                <h3 style={{fontSize:'18px'}}><strong>Montage datum:</strong> {quotationData.montage_date}</h3>      
                                <CustomerCareQuotationData basicInformation={JSON.parse(quotationData.product_rules)}/>                                                         
                                {JSON.parse(quotationData.product_rules).extra_stairs.map((stair) => <CustomerCareQuotationData basicInformation={stair}/>      )}

                            </div>
                        ) : (
                            <Loader container={false}/>
                        )}
                    </div>
                </div>
            )}

            {modalCustomerCareIsOpen && (
                <CustomerCareFormModal 
                    isOpen={modalCustomerCareIsOpen} 
                    closeModal={closeFormModal} 
                    careId={o.id}
                    fetchCustomerCare={fetchCustomerCare}
                />
            )}
        </div>
    );
};

export default CustomerCare;
