import axios from "axios";
import { CheckCircle } from "phosphor-react";
import { useEffect, useMemo, useState } from "react";

const ExtraFinish = (props) => {

    const {basicInformation} = props;

    const moneyFormat = (nr) => {
        return nr.toLocaleString('nl-NL', { style: 'currency', currency: 'EUR' })
     }

    const getNumberOfProduct = (name) => {
        let find =  basicInformation.numbers.find(a => a.name === name);
        if(find){
            return find.value;
        }
        return 0
    }

    const capitalizeString = (str) => {
        if(str !== ''){
            const lower = str.toLowerCase();
            return str.charAt(0).toUpperCase() + lower.slice(1)
        } else {
            return ''
        }
    }

    const getQuantity = (type,name) => {

        let value = 0;

        if(type == 'accessoires'){
            basicInformation.numbers && basicInformation.numbers.accessoires && basicInformation.numbers.accessoires.find(o => {
                if(o.name === name)  {
                    console.log(o.value)
                    value = parseInt(o.value)
                }
                    
            })
        } else {
            basicInformation.numbers && basicInformation.numbers.labors && basicInformation.numbers.labors.map(o => {
                if(o.name === name)  {
                    console.log(o.value)
                    value = parseInt(o.value)
                }
                    
            })
        }
        return parseInt(value);
    }


    useEffect(() => {
        console.log('extra finish info', basicInformation)
    },[basicInformation])

    return (
        <>
 { basicInformation &&
                <div className="card mt-3">
                    <div className="card-body p-0">
                    <h4 style={{background:'#212529', color:'#fff', borderTopRightRadius:'5px', borderTopLeftRadius:'5px'}}>{basicInformation.stairs_description} </h4>
                        <ul className="list-group rounded-0">
                           
                            <li className="list-group-item">
                            
                                <div className="row">
                                    <div className="col-3">
                                        <p className="m-0 fw-bold">Type</p>
                                        {basicInformation.type && basicInformation.type == 'no' ? 'Dicht' : 'Open'} 
                                    </div>
                                    <div className="col-3 text-start">
                                    <p className="m-0 fw-bold">Model</p>
                                    {basicInformation.model} 
                                    </div>
                                    <div className="col-3">
                                    <p className="m-0 fw-bold">Materiaal</p>
                                        {basicInformation.selectedMaterial && basicInformation.selectedMaterial.name}
                                    </div>
                                    <div className="col-3">
                                    <p className="m-0 fw-bold">Kleur trede</p>
                                        {basicInformation.selectedMaterial && basicInformation.color_step !== "" && basicInformation.selectedMaterial.colors.find(a => a.id == basicInformation.color_step) && basicInformation.selectedMaterial.colors.find(a => a.id == basicInformation.color_step).name}
                                        {basicInformation.color_step == "" &&
                                            (
                                                <p className="alert p-1 bg-danger text-white">Let op, geen kleur!</p>
                                            )
                                        }
                                    </div>
                                </div>
                            </li>                         
                            <li className="list-group-item">
                                <div className="row">
                                    <div className="col-12">
                                    <p className="m-0 fw-bold">Overzettreden</p>
                                    </div>
                                </div>
                                { basicInformation && basicInformation.hasOwnProperty('transfer_steps') && basicInformation.transfer_steps.map((o,k) =>{

                                    return (
                                        <div className="row" key={k}>
                                    <div className="col-6"> 
                                        <p className="mb-0 fw-bold">{o.name}</p>
                                        <small className="d-block text-muted">Aantal: {o.number}</small>
                                    </div>
                                    <div className="col-6 text-end">
                                     {moneyFormat(o.number * o.price)}
                                    </div>
                                </div>
                                    )
                                })

                                }
                            </li>

                            <li className="list-group-item">
                                <div className="row">
                                    <div className="col-6">
                                    <p className="mb-0 fw-bold">Stootbord</p>
                                    {basicInformation.selectedMaterial && basicInformation.color_riser !== null && basicInformation.color_riser !== "" && basicInformation.selectedMaterial.colorrisers.find(a => a.id == basicInformation.color_riser).name}
                                    <small className="d-block text-muted">Aantal: {getNumberOfProduct('color_riser')}</small>
                                    </div>
                                    
                                    <div className="col-6 text-end">
                                    {basicInformation.numbers && basicInformation.selectedMaterial && basicInformation.color_riser !== null && basicInformation.color_riser !== "" && moneyFormat(basicInformation.selectedMaterial.colorrisers.find(a => a.id == basicInformation.color_riser).price * getNumberOfProduct('color_riser'))}
                                    </div>
                                </div>
                            </li>
                            <li className="list-group-item">
                                <div className="row">
                                    <div className="col-6">
                                    <p className="m-0 fw-bold">Uitlooptrede</p>
                                    {basicInformation.selectedMaterial && basicInformation.exit_step !== "" && basicInformation.exit_step !== null && basicInformation.selectedMaterial.exitsteps.find(a => a.id == basicInformation.exit_step).name}
                                    <small className="d-block text-muted">Aantal:{getNumberOfProduct('exit_step')}</small>
                                    </div>
                                    <div className="col-6 text-end">
                                    {basicInformation.numbers && basicInformation.selectedMaterial && basicInformation.selectedMaterial && basicInformation.exit_step !== "" && basicInformation.exit_step !== null && moneyFormat(basicInformation.selectedMaterial.exitsteps.find(a => a.id == basicInformation.exit_step).price * getNumberOfProduct('exit_step'))}
                                    </div>
                                </div>
                            </li>
                            {/* <li className="list-group-item">
                                <div className="row">
                                    <div className="col-6">
                                    <p className="m-0 fw-bold">Wangen</p>
                                    {basicInformation.cheeks}
                                 
                                    </div>
                                    <div className="col-6 text-end">

                                    </div>
                                </div>
                            </li> */}
                              { basicInformation.cheeks !== null && basicInformation.cheeks !== "nee" &&
                            <li className="list-group-item">
                                <div className="row">
                                    <div className="col-6">
                                    <p className="m-0 fw-bold">Kleur Wangen</p>
                                    {basicInformation.selectedMaterial && basicInformation.color_cheeks !== "" && basicInformation.color_cheeks !== null && basicInformation.selectedMaterial.cheeks.find(a => a.id == basicInformation.color_cheeks).name}
                                    <small className="d-block text-muted">Aantal: {getNumberOfProduct('color_cheeks')}</small>
                                    </div>
                                    <div className="col-6 text-end">
                                    {basicInformation.numbers && basicInformation.selectedMaterial && basicInformation.color_cheeks !== null && basicInformation.color_cheeks !== "" && moneyFormat(basicInformation.selectedMaterial.cheeks.find(a => a.id == basicInformation.color_cheeks).price * getNumberOfProduct('color_cheeks'))}
                                    </div>
                                </div>
                            </li>      
                            }         
                             { basicInformation.cheeks !== null && basicInformation.cheeks !== "nee" &&        
                            <li className="list-group-item">
                                <div className="row">
                                    <div className="col-6">
                                    <p className="m-0 fw-bold">Kleur L profiel en U profiel</p>
                                    {basicInformation.selectedMaterial && basicInformation.color_l_u_profile !== "" && basicInformation.color_l_u_profile !== null && basicInformation.selectedMaterial.landus.find(a => a.id == basicInformation.color_l_u_profile).name}
                                    <small className="d-block text-muted">Aantal: {getNumberOfProduct("l_profile")}</small>
                                    </div>
                                    <div className="col-6 text-end">
                                    {basicInformation.numbers && basicInformation.selectedMaterial && basicInformation.color_l_u_profile !== null &&  basicInformation.color_l_u_profile !== "" &&  moneyFormat(basicInformation.selectedMaterial.landus.find(a => a.id == basicInformation.color_l_u_profile).price * getNumberOfProduct("l_profile"))}
                                    </div>
                                </div>
                            </li>
                            }
                        </ul>
                       
                        
                           {/* <p className="mb-0">
                           <strong>Kleur trede:</strong> {basicInformation.color_step}
                           </p>
                           <p className="mb-0">
                           <strong>Kleur stootbord:</strong> {basicInformation.color_riser}
                           </p>
                           <p className="mb-0">
                           <strong>Uitlooptrede:</strong> {basicInformation.exit_step}
                           </p>
                           <p className="mb-0">
                           <strong>Wangen:</strong> {basicInformation.cheeks}
                           </p>
                           <p className="mb-0">
                           <strong>Kleur wangen:</strong> {basicInformation.color_cheeks}
                           </p>
                           <p className="mb-0">
                           <strong>Kleur L profiel en U profiel:</strong> {basicInformation.color_l_u_profile}
                           </p>                      */}
                           <h4 className="py-2">Accessoires</h4>
                           <ul className="list-group rounded-0">
                           { basicInformation.selectedAccessoires && basicInformation.selectedAccessoires.map((a,k) => {
                            return (
                                <li className="list-group-item" key={k}>
                                    <div className="row">
                                        <div className="col-6">
                                        <p className="m-0 fw-bold">{a.name}</p>
                                        <small className="d-block text-muted">Aantal: {a.number}</small>
                                        </div>
                                        <div className="col-6 text-end">
                                        <p className="m-0">{moneyFormat(parseInt(a.price) * a.number)}</p>                               
                                        </div>
                                    </div>
                                </li>
                            )
                           })
                           }
                           </ul>
                           { basicInformation.selectedLabors && basicInformation.selectedLabors.length > 0 &&
                           <>
                           <h4 className="py-2">Arbeid</h4>
                           <ul className="list-group rounded-0">
                           { basicInformation.selectedLabors && basicInformation.selectedLabors.map((a,k) => {
                            return (
                                <li className="list-group-item" key={k}>
                                    <div className="row">
                                    <div className="col-6">
                                        <p className="m-0 fw-bold">{a.name}</p>
                                        <small className="d-block text-muted">Aantal: {a.number}</small>
                                        </div>
                                        <div className="col-6 text-end">
                                        <p className="m-0">{moneyFormat(parseInt(a.price) * a.number)}</p>
                                        
                                        </div>
                                    </div>
                                </li>
                            )
                           })
                           }
                           </ul>
                           </>
}
{ basicInformation.extra_description && basicInformation.extra_description.length >= 1 &&
                            <>
                             <h4 className="py-2">Overige opmerkingen</h4>
                             <ul className="list-group rounded-0">
                             <li className="list-group-item">
                                <p className="mb-0" style={{whiteSpace:'pre-line'}}>{basicInformation.extra_description}</p>
                                </li>
                                </ul>
                            </>
                           }
                        </div>
                        </div>
 }
        </>
    )
}

export default ExtraFinish;