window._ = require('lodash');

try {
    require('bootstrap');
} catch (e) {}

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo';

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     forceTLS: true
// });


//Code for sorting

if (window.jQuery) {
    $(document).ready(function() {
        $('.labor-list-group').sortable({
            items: 'li',
            cursor: 'move',
            opacity: 0.6,
            update: function() {
                updateOrder();
            }
        });
    });
    
    function updateOrder() {
        var order = [];
        $('.list-group-item').each(function(index, element) {
            order.push({
                id: $(this).find('.drag-icon').data('id'),
                position: index + 1
            });
        });
    
        $.ajax({
            type: 'PUT',
            dataType: 'json',
            url: '/beheer/arbeid/order/update',
            data: {
                order: order,
                _token: $('meta[name="csrf-token"]').attr('content')
            },
            success: function(response) {
                Swal.fire({
                    title: 'Successvol geupdate!',
                    position: "top-end",
                    icon: "success",
                    timer: 1500,
                    showConfirmButton: false,
                });
            }
        });
    }



    $(document).ready(function() {
        $('.acc-list-group').sortable({
            items: 'li',
            cursor: 'move',
            opacity: 0.6,
            update: function() {
                updateAccOrder();
            }
        });
    });


    function updateAccOrder() {
        var order = [];
        $('.list-group-item').each(function(index, element) {
            order.push({
                id: $(this).find('.drag-icon').data('id'),
                position: index + 1
            });
        });
    
        $.ajax({
            type: 'PUT',
            dataType: 'json',
            url: '/beheer/accessoires/order/update',
            data: {
                order: order,
                _token: $('meta[name="csrf-token"]').attr('content')
            },
            success: function(response) {
                Swal.fire({
                    title: 'Successvol geupdate!',
                    position: "top-end",
                    icon: "success",
                    timer: 1500,
                    showConfirmButton: false,
                });
            }
        });
    }
}