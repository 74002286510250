import React, { useState, StrictMode, useMemo, useEffect } from "react";
import ReactDOM from "react-dom";
import { createRoot } from "react-dom/client";
import axios from "axios";
import { CaretRight } from "phosphor-react";

export default function SearchCustomers() {

    const [results,setResults] = useState([]);
    const [search,setSearch] = useState('');

    const handleSearch = (e) => {
        let search = e.target.value;
        setSearch(e.target.value)
        if(search.length < 1){
            setResults([])
        }
        if(search.length > 2){
            axios.get('/beheer/klanten/search/'+e.target.value).then( res => {
                console.log(res)
                setResults(res.data)
            })
        }
    }

    const resetSearch = () => {
        setResults([])
        setSearch('')
    }

    return (
        <>
        <input className="form-control form-control-dark w-100 rounded-0 border-0" type="text" onChange={handleSearch} value={search} placeholder="Zoek op klantnaam" aria-label="Search"/>
        { results.length > 0 &&
            <button className="position-absolute end-0 top-0 btn bg transparent border-0 rounded-0" onClick={() => resetSearch()}>X</button>
        }
        { results.length > 0 &&
            <div className="customer-search-results position-absolute w-100" >
                <ul className="list-group w-100" style={{height:'400px', overflowY:'auto'}}>
                { results.map( (o,k) => {
                    return (
                        <li className="list-group-item" key={k}>
                            <a href={"/beheer/klant/" + o.id} className="d-flex w-100 align-items-center bg:hover" style={{color:'#000', textDecoration:'none', columnGap:'7px'}}>
                               <CaretRight size={16}/> 
                               <div style={{lineHeight:'16px'}}>
                                <p className="m-0 fw-bold">{o.firstname} {o.lastname}</p>
                                <small className="text-muted">{o.street} {o.number}, {o.zipcode}, {o.place}</small>
                               </div>
                            </a>
                        </li>
                    )
                })

                }
                </ul>
            </div>
        }
        </>
    )
}


const rootElement = document.getElementById("search_customers");
if(rootElement){
    const root = createRoot(rootElement);
    root.render(<SearchCustomers />);
}
