// Loader.js
import React from 'react';

const Loader = (props) => {
    const { container = true } = props;
    return (
      <div className={container ? "loader-container" : "text-center w-100"}>
        <div className="spinner-border text-primary" role="status" style={{ width: '4rem', height: '4rem' }}>
          <span className="sr-only d-none">Loading...</span>
        </div>
        <p className="loading-text">Gegevens worden geladen...</p>
      </div>
    );
  };

export default Loader;
