import {
    PencilCircle,
    PencilSimple,
    PlusCircle,
    TrashSimple,
} from "phosphor-react";
import { useEffect, useRef, useState } from "react";
import BasicInformation from "../BasicInformation";

const VariantTabs = (props) => {

    const {goToStep, setBasicInformation, basicInformation, tabs, setTab,materials,labors,accessoires,client} = props;


  
    const [syncMode, setSyncMode] = useState(false);
    const [selectedTab, setSelectedTab] = useState(null);

    const updateBasicInformation = (data,i) => {
        console.log('save stair', i , data)

        const updated_data = basicInformation.map((c,index) => {
            if(i === index) {
                return data;
            } else {
                return c
            }
        })

        setBasicInformation((prev) => updated_data)

        console.log('basic',i,data,basicInformation)
    }


    const addTab = () => {
        let newTab = { name: "Variant name", selected: true };
        setTab(prevTabs => {
            const updatedTabs = prevTabs.map(tab => ({ ...tab, selected: false }));
            return [...updatedTabs, newTab];
        });

        let newInfo = {
            stairs_description: null,
            type: null,
            model: null,
            material: null,
            color_step: null,
            color_riser: null,
            exit_step: null,
            transfer_step: null,
            cheeks: 'nee',
            color_cheeks: null,
            color_l_u_profile: null,
            extra_description: null,
            selectedMaterial: null,
            transfer_steps: [{}],
            numbers: [],
            extra_stairs: []
        };
        setBasicInformation(prevInfo => [...prevInfo, newInfo]);
    };

    const selectTab = (i) => {
        setTab((prevState) => {
            const newState = prevState.map((obj, k) => {
                // 👇️ if id equals 2, update country property
                console.log('find my good data tabje',obj,k)
                if (k === i) {
                    return { ...obj, selected: true };
                } else {
                    return { ...obj, selected: false };
                }
            });

            return newState;
        });
    };

    const deleteTab = (index) => {
        if (confirm('Weet je zeker dat je deze variant wil verwijderen?')) {
        if(tabs.length > 1){
        setTab([...tabs.slice(0, index), ...tabs.slice(index + 1)]);
        }
    } else {
        // Do nothing
    }
    };

    const editTab = (index, obj) => {
        obj = {...obj, index: index}
        setSelectedTab(obj);
    };

    const saveTab = (e) => {
        e.preventDefault()
        let a = selectedTab;
        let form = new FormData(e.target)
        setTab((prevState) => {
            const newState = prevState.map((obj, k) => {
                // 👇️ if id equals 2, update country property
                if (k === a.index) {
                    return { ...obj, name: form.get('name') };
                } else {
                    return obj
                }
            });

            return newState;
        });
        setSelectedTab(null)
    }

    return (
        <>
        { syncMode == true &&
                    (
                        <div 
                    style={{
                       position: "fixed",
                       background: "#fff",
                       width: "100%",
                       left:'0',
                       right:0,                     
                       textAlign:'center',
                       top:0,
                       bottom:0, 
                       height: "100%",                      
                       zIndex: 9999,
                       padding: "15px",
                   }}  
                   >
                    <div style={{marginTop:'40vh'}}>
                    <div className="d-flex justify-content-center">
  <div className="spinner-border text-primary"  style={{width:'3rem',height:'3rem'}} role="status">
    <span className="visually-hidden">Loading...</span>
  </div>
</div>
                       <p className="fw-bold mt-4">Gegevens inladen...</p>
                   </div>
                   </div>
                    )
                }
{selectedTab !== null && syncMode == false && (
                <>
               
                    <div
                        style={{
                            position: "fixed",
                            background: "#fff",
                            width: "400px",
                            left:'50%',
                            marginLeft:'-200px',
                            height: "auto",
                            borderRadius: "10px",
                            zIndex: 9999,
                            padding: "15px",
                        }}  
                    >

                        <form onSubmit={(e) => saveTab(e)}>
                            <p className="fw-bold">Wijzig variant naam</p>
                            <input defaultValue={selectedTab && selectedTab.name} name="name" className="form-control"/>
                            <button type="submit" className="btn btn-primary w-100 mt-2">Opslaan</button>
                        </form>

                    </div>
                    <div
                        style={{
                            position: "fixed",
                            background: "#000",
                            opacity: 0.5,
                            zIndex: 888,
                            left: 0,
                            right: 0,
                            top: 0,
                            bottom: 0,
                        }}
                        onClick={(e) => setSelectedTab(null)}
                    ></div>
                </>
            )}

            <div className="variant-tabs">
                {tabs && 
                    tabs.map((t, k) => {
                        return (
                            <div
                                className={
                                    "variant-tab-single" +
                                    (t.selected ? " selected" : "")
                                }
                                key={k}
                                onClick={(e) => selectTab(k)}
                            >
                                <div className="d-flex w-100">
                                    <p className="mb-0">{t.name}</p>
                                    <button type="button" onClick={(e) => editTab(k)}>
                                    <PencilSimple
                                        size={21}                                        
                                    />
                                    </button>
                                    <button onClick={(e) => deleteTab(k)}>
                                    <TrashSimple
                                        size={21}                                        
                                    />
                                    </button>
                                </div>
                            </div>
                        );
                    })}
                <div className="add-variant-tab" onClick={(e) => addTab(e)}>
                    <PlusCircle size={24} />
                </div>
            </div>
            {tabs &&
                    tabs.map((t, k) => {
                        return (
            <div key={k} className={
                "variant-tab-content" +
                (t.selected ? " selected" : "")
            }>
                           { t.selected && 

<BasicInformation
key={k}
goToStep={goToStep}
variantTab={k}
setBasicInformation={(data,k) => updateBasicInformation(data,k)}
basicInformation={basicInformation[k]}                
materials={materials} 
labors={labors}
accessoires={accessoires}            
syncMode={syncMode}  
setSyncMode={setSyncMode}
client={client}
/>

                           }
            </div>
    )
}) }

            
        </>
    );
};

export default VariantTabs;
