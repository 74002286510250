import { ArrowRight, PlusCircle } from "phosphor-react";
import React, { useEffect, useMemo, useRef, useState } from "react";

import ExtraBasicInformation from "./ExtraBasicInformation";
import { syncData } from "./utils/SyncData";

const BasicInformation = (props) => {
    const { goToStep, variantTab, setBasicInformation, basicInformation, materials,labors,accessoires, syncMode, setSyncMode, client } = props;

    let form = useRef(null)


    const [sync, setSync] = useState(false);
    const [nrSynced, setNrSynced] = useState(0);

    const [selectedMaterial, setSelectedMaterial] = useState(null);

    const [selectedAccessoires, setSelectedAccessoires] = useState([]);
    const [selectedLabors, setSelectedLabors] = useState([]);

    const [debouncedAccessoires, setDebouncedAccessoires] = useState(selectedAccessoires);
    const [debouncedLabors, setDebouncedLabors] = useState(selectedLabors);

    const [formData, setFormData] = useState({
        stairs_description: null,
        type: null,
        model: null,
        material: null,
        color_step: null,
        color_riser: null,
        exit_step: null,
        transfer_step: null,
        cheeks: 'nee',
        color_cheeks: null,
        color_l_u_profile: null,
        extra_description: null,
        // landing_floor: null,
        // color_landing_floor: null,
        // remove_carpet: null,
        selectedMaterial: null,
        transfer_steps: [{}],
        numbers: [],
        extra_stairs: []
    });

    

    const handleSubmit = (e) => {
        e.preventDefault();
    };

    const changeSelectedMaterial = (e) => {
        const selected = materials.find((a) => a.id == e);
        setSelectedMaterial(selected);
        // setBasicInformation(prev => {prev.material, selected.id})
        // console.log(selected);
    };

    const addTransferStep = (e) => {
        e.preventDefault()
        let tmp = formData;
        tmp.transfer_steps.push({});
        // // console.log(tmp)
        setFormData((a) => {
            return { ...a, transfer_steps: tmp.transfer_steps };
        });
    };

    const removeTransferStep = (i) => {
        let tmp = formData;
        tmp.transfer_steps.splice(i, 1);
        // // console.log(tmp)
        setFormData((a) => {
            return { ...a, transfer_steps: tmp.transfer_steps };
        });
    };

    const removeStairs = (i) => {
        let tmp = formData;
        tmp.extra_stairs.splice(i, 1);
        // // console.log(tmp)
        setFormData((a) => {
            return { ...a, extra_stairs: tmp.extra_stairs };
        });
    };

    const changeTransferStep = (e, i, type, obj) => {
        // console.log("transfer change", type);
        let tmp = formData;
        let value = e.target.value;
        if (type === "number") {
            //Update number of transfer step
            tmp.transfer_steps[i].number = value;
        } else {
            tmp.transfer_steps[i].type = value;
            //Set default data
            tmp.transfer_steps[i].name = obj.name;
            tmp.transfer_steps[i].price = obj.price;
            tmp.transfer_steps[i].id = obj.id;
        }

        //Update state
        setFormData((a) => {
            return { ...a, transfer_steps: tmp.transfer_steps };
        });
        setBasicInformation(formData,variantTab);
    };

    const addNewStairs = (e) => {
        e.preventDefault() 
        let tmp = basicInformation;
        tmp.extra_stairs.push({
            stairs_description: null,
            type: null,
            model: null,
            material: null,
            color_step: null,
            color_riser: null,
            exit_step: null,
            transfer_step: null,
            cheeks: 'nee',
            color_cheeks: null,
            color_l_u_profile: null,
            extra_description: null,
            // landing_floor: null,
            // color_landing_floor: null,
            // remove_carpet: null,
            selectedMaterial: null,
            transfer_steps: [{}],
            numbers: []
        });
        // // console.log(tmp)
        setFormData((a) => {
            return { ...a, extra_stairs: tmp.extra_stairs };
        });
        setBasicInformation(tmp,variantTab);
    }



    const updateObjectNumbers = (field, value) => {
        let tmp = basicInformation.numbers;
        // tmp[field] = value;
        if (tmp.find((o, k) => o.name === field)) {
            tmp.map((o, k) => {
                if (o.name == field) {
                    o.value = value;
                }
            });
        } else {
            tmp.push({ name: field, value: value });
        }

        setFormData((a) => {
            return { ...a, numbers: tmp };
        });
        setBasicInformation(formData, variantTab);
        
    };

    const updateStairs = (data, index) => {
        // Ensure the update happens correctly within the extra_stairs array
        const updatedExtraStairs = basicInformation.extra_stairs.map((stairs, i) => 
            i === index ? data : stairs
        );
    
        // Update the basicInformation with the new extra_stairs array
        const updatedBasicInformation = {
            ...basicInformation,
            extra_stairs: updatedExtraStairs
        };
    
        // Set the updated formData and basicInformation state
        setFormData(updatedBasicInformation);
        setBasicInformation(updatedBasicInformation, variantTab);
    };
    

    const updateNumbers = (value, obj, type) => {
        const parsedValue = parseInt(value);
        let updatedTmp;
    
        if (type === "accessoires") {
            updatedTmp = basicInformation.selectedAccessoires.map(a => (a.id === obj.id ? { ...a, number: parsedValue } : a));
            setSelectedAccessoires(updatedTmp);
        } else {
            updatedTmp = basicInformation.selectedLabors.map(a => (a.id === obj.id ? { ...a, number: parsedValue } : a));
            setSelectedLabors(updatedTmp);
        }
    
        setFormData(prevFormData => {
            const updatedFormData = {
                ...prevFormData,
                [type === 'accessoires' ? 'selectedAccessoires' : 'selectedLabors']: updatedTmp
            };
            setBasicInformation(updatedFormData, variantTab);
            return updatedFormData;
        });
    };
    

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedAccessoires(selectedAccessoires);
            setDebouncedLabors(selectedLabors);
        }, 300); // Adjust the debounce delay as needed
    
        return () => {
            clearTimeout(handler);
        };
    }, [selectedAccessoires, selectedLabors]);
    
    // This effect now triggers only when debounced values change
    useEffect(() => {
        setFormData(prevFormData => ({
            ...prevFormData,
            selectedAccessoires: debouncedAccessoires,
            selectedLabors: debouncedLabors,
        }));
    }, [debouncedAccessoires, debouncedLabors]);

    
    useEffect(() => {
        setBasicInformation(formData, variantTab);
    }, [formData, variantTab, client, basicInformation]);
    
    

    const onChangeForm = (e) => {
        

        
        let obj = {
            stairs_description: e.target.form.stairs_description ? e.target.form.stairs_description.value : null,
            type: e.target.form.type ? e.target.form.type.value : null,
            model: e.target.form.model ? e.target.form.model.value : null,
            material: e.target.form.material ? e.target.form.material.value : null,
            color_step: e.target.form.color_step ? e.target.form.color_step.value : null,
            color_riser: e.target.form.color_riser ? e.target.form.color_riser.value : null,
            // transfer_step: e.target.form.transfer_step.value,
            exit_step: e.target.form.exit_step ? e.target.form.exit_step.value : null,
            cheeks: e.target.form.cheeks ? e.target.form.cheeks.value : 'nee',
            color_cheeks: e.target.form.color_cheeks ? e.target.form.color_cheeks.value : null,
            color_l_u_profile: e.target.form.color_l_u_profile ? e.target.form.color_l_u_profile.value : null,
            extra_description: e.target.form.extra_description ? e.target.form.extra_description.value : null,
            // landing_floor: e.target.form.landing_floor.value,
            // color_landing_floor: e.target.form.color_landing_floor.value,
            // remove_carpet: e.target.form.remove_carpet.value,
            selectedAccessoires: selectedAccessoires,
            selectedLabors: selectedLabors,
            selectedMaterial: selectedMaterial, 
            transfer_steps: basicInformation.transfer_steps,
            numbers: basicInformation.numbers,
            extra_stairs: basicInformation.extra_stairs
        };

        setFormData(obj);

        console.log('update form data', obj, variantTab)

        setBasicInformation(obj,variantTab);
 
        // console.log(obj);
    };

    const changeLabors = (e, o) => {
        let tmp = selectedLabors ? selectedLabors : [];
        let found = false;
        if(tmp){
            tmp.map((a) => {
                if (a.id == o.id) {
                    found = true;
                }
            });
        }
        if (!found) {
            o.number = 1;
            tmp.push(o);
            setSelectedLabors(tmp);
        } else {
            let new_array = tmp.filter((a) => {
                return o.id !== a.id;
            }); 
            setSelectedLabors(new_array);
        }
    };

    const changeAccessoires = (e, o) => {
        let tmp = selectedAccessoires ? selectedAccessoires : [];
        let found = false;
        if(tmp){
            tmp.map((a) => {
                if (a.id == o.id) {
                    found = true;
                }
            });
        }
        if (!found) {
            o.number = 1
            tmp.push(o);
            setSelectedAccessoires(tmp);
        } else {
            let new_array = tmp.filter((a) => {
                return o.id !== a.id;
            });
            setSelectedAccessoires(new_array);
        }
    };

    const exists = (o, array) => {
        // console.log('exists',o,array)
        let found = false;
        if(array){
            array.map(a => {
                if(a.name == o.name){
                    found = true
                }
            })
        }
        return found
    };

    

    const getNumber = (o, array) => {
        let nr = null;
        array.map(a => {
            if(a.name == o.name){
                nr = a.number
            }
        })
        return nr
    };

    
    

    
    useEffect(() => {
        if(basicInformation && basicInformation.selectedLabors && basicInformation.material && basicInformation.selectedAccessoires && !sync){   

            changeSelectedMaterial(basicInformation.material)
            setSelectedAccessoires(basicInformation.selectedAccessoires)
            setSelectedLabors(basicInformation.selectedLabors)
            setFormData(basicInformation);

            setSync(true)
       
    }},[basicInformation, client])   

    return (
   
        <>

            <h4 className="fw-bold">Basis informatie</h4>
            { basicInformation &&
                <div>
                <form
                className="row"
                ref={form}
                onSubmit={handleSubmit}
                onChange={(e) => onChangeForm(e)}
            >
                <div className="question-group col-12">
                    <p className="fw-bold mb-0 mt-2">Trap omschrijving</p>
                    <input className="form-control" value={basicInformation.stairs_description} onChange={(e) => setBasicInformation(prev => {prev.stairs_description, e.target.value})} name="stairs_description"/>
                </div>
                    <div className={"question-group col-4" + (selectedMaterial !== null ? '' : ' col-12')}>
                        <p className="fw-bold mb-0 mt-2">Type trap</p>
                        <div
                            className="btn-group w-100"
                            role="group"
                            aria-label="Basic radio toggle button group"
                        >
                           
                            <input
                                type="radio"
                                value="no"
                                className="btn-check"
                                name="type"
                                id={"type2"+variantTab}
                                autoComplete="off"                                
                                onChange={e => setBasicInformation(prev => {prev.type, e.target.value})}
                                checked={basicInformation.type == 'no'}
                            />
                            <label
                                className={"btn btn-outline-primary" + (selectedMaterial !== null ? '' : ' btn-lg')}
                                htmlFor={"type2"+variantTab}
                            >
                                Dicht
                            </label>
                            <input
                                type="radio"
                                value="yes"
                                className="btn-check"
                                name="type"
                                id={"type1"+variantTab}
                                autoComplete="off"
                                onChange={e => setBasicInformation(prev => {prev.type, e.target.value})}
                                checked={basicInformation.type == 'yes'}
                            />
                            <label
                              className={"btn btn-outline-primary" + (selectedMaterial !== null ? '' : ' btn-lg')}
                                htmlFor={"type1"+variantTab}
                            >
                                Open
                            </label>
                        </div>
                    </div>
                    <div className={"question-group col-4" + (selectedMaterial !== null ? '' : ' col-12')}>
                        <p className="fw-bold mb-0 mt-2">Model</p>
                        <div
                            className="btn-group w-100"
                            role="group"
                            aria-label="Basic radio toggle button group"
                        >
                            <input
                                type="radio"
                                className="btn-check"
                                value="recht"
                                name="model"
                                id={"model1"+variantTab}
                                autoComplete="off"
                                checked={basicInformation.model == 'recht'}
                                onChange={e => setBasicInformation(prev => {prev.model, e.target.value})}
                            />
                            <label
                            className={"btn btn-outline-primary" + (selectedMaterial !== null ? '' : ' btn-lg')}
                                htmlFor={"model1"+variantTab}
                            >
                                Recht
                            </label>
                            <input
                                type="radio"
                                className="btn-check"
                                value="enkelkwart"
                                name="model"
                                id={"model2"+variantTab}
                                autoComplete="off"
                                checked={basicInformation.model == 'enkelkwart'}
                                onChange={e => setBasicInformation(prev => {prev.model, e.target.value})}
                            />
                            <label
                                className={"btn btn-outline-primary" + (selectedMaterial !== null ? '' : ' btn-lg')}
                                htmlFor={"model2"+variantTab}
                            >
                                Enkelkwart
                            </label>
                            <input
                                type="radio"
                                className="btn-check"
                                value="dubbelkwart"
                                name="model"
                                id={"model3"+variantTab}
                                autoComplete="off"
                                checked={basicInformation.model == 'dubbelkwart'}
                                onChange={e => setBasicInformation(prev => {prev.model, e.target.value})}
                            />
                            <label
                             className={"btn btn-outline-primary" + (selectedMaterial !== null ? '' : ' btn-lg')} 
                                htmlFor={"model3"+variantTab}
                            >
                                Dubbelkwart
                            </label>
                        </div>
                    </div>
                    <div className={"question-group col-4" + (selectedMaterial !== null ? '' : ' col-12')}>
                        <p className="fw-bold mb-0 mt-2">Materiaal</p>
                        <select
                            className="form-control"
                            name="material"
                            value={basicInformation.material}
                            onChange={(e) => changeSelectedMaterial(e.target.value)}
                        >
                              <option value="">Selecteer optie</option>
                            {materials.length > 0 &&
                                materials.map((o, k) => {
                                    return (
                                        <option key={k} value={o.id}>
                                            {o.name}
                                        </option>
                                    );
                                })}
                        </select>
                    </div>

                    { selectedMaterial &&
                        <>
                        <div className="question-group col-12">
                            <div className="row">
                            <div className="col-12">
                                <p className="fw-900 fs-4 mb-0 mt-2">
                                    Tredes
                                </p>
                            </div>

                            </div>
                        <p className="fw-bold mb-0 mt-2">Kleur</p>
                        <select className="form-control" name="color_step" value={basicInformation.color_step}>
                            <option value="">Selecteer optie</option>
                            {selectedMaterial &&    
                                selectedMaterial.colors.map((o, k) => {
                                    return (
                                        <option value={o.id} key={k}>
                                            {o.name}
                                        </option>
                                    );
                                })}
                        </select>
                    </div>
                    <div className="question-group col-12">
                        {/* <p className="fw-bold mb-0 mt-2">Overzettredes</p> */}
                        <div className="row">
                            <div className="col-12">
                                <p className="fw-900 fs-4 mb-0 mt-2">
                                    Overzettreden
                                </p>
                            </div>
                            {basicInformation &&
                                basicInformation.transfer_steps &&
                                basicInformation.transfer_steps.map((o, k) => {
                                    return (
                                        <div className="col-12" key={k}>
                                            <div className="row">
                                                <div className="col-7">
                                                    <p className="fw-bold mb-0 mt-2">
                                                        Maat
                                                    </p>
                                                    <select
                                                        className="form-control"
                                                        name="transfer_step"
                                                        value={o.hasOwnProperty('type') ? o.type : ''}
                                                        onChange={(e) =>
                                                            changeTransferStep(
                                                                e,
                                                                k,
                                                                "type",
                                                                {
                                                                    name: e
                                                                        .target
                                                                        .options[
                                                                        e.target
                                                                            .selectedIndex
                                                                    ].dataset
                                                                        .name,
                                                                    price: e
                                                                        .target
                                                                        .options[
                                                                        e.target
                                                                            .selectedIndex
                                                                    ].dataset
                                                                        .price,
                                                                    id: e
                                                                        .target
                                                                        .options[
                                                                        e.target
                                                                            .selectedIndex
                                                                    ].dataset
                                                                        .id,
                                                                }
                                                            )
                                                        }
                                                    >
                                                        <option value="">Selecteer optie</option>
                                                        {selectedMaterial &&
                                                            selectedMaterial.transfersteps.map(
                                                                (o, k) => {
                                                                    return (
                                                                        <option
                                                                            value={
                                                                                o.id
                                                                            }
                                                                            key={
                                                                                k
                                                                            }
                                                                            data-name={
                                                                                o.name
                                                                            }
                                                                            data-price={
                                                                                o.price
                                                                            }
                                                                            data-id={
                                                                                o.id
                                                                            }
                                                                        >
                                                                            {
                                                                                o.name
                                                                            }
                                                                        </option>
                                                                    );
                                                                }
                                                            )}
                                                    </select>
                                                </div>
                                                <div className="col-4">
                                                    <p className="fw-bold mb-0 mt-2">
                                                        Aantal
                                                    </p>
                                                    <input
                                                        type="number" min="1"
                                                        className="form-control"
                                                        name="transfer_step_nr"
                                                        defaultValue={o.hasOwnProperty('number') ? o.number : ''}
                                                        onChange={(e) =>
                                                            changeTransferStep(
                                                                e,
                                                                k,
                                                                "number",
                                                                o
                                                            )
                                                        }
                                                        placeholder="Aantal"
                                                    />
                                                </div>
                                                <div className="col text-center d-flex">
                                                    {k !== 0 && (
                                                        <button
                                                            type="button"
                                                            onClick={() =>
                                                                removeTransferStep(
                                                                    k
                                                                )
                                                            }
                                                            className={
                                                                "btn btn-danger btn-sm align-self-end w-100"
                                                            }
                                                        >
                                                            Verwijder
                                                        </button>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <button
                                    type="button"
                                    className="btn btn-secondary w-100 mt-3"
                                    onClick={(e) => addTransferStep(e)}
                                >
                                    + Overzettrede toevoegen
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="question-group col-12">
                        <div className="row">
                        <div className="col-12">
                                <p className="fw-900 fs-4 mb-0 mt-2">
                                    Stootbord
                                </p>
                            </div>
                            <div className="col-6">
                                <p className="fw-bold mb-0 mt-2">
                                    Type
                                </p>
                                <select
                                    className="form-control"
                                    name="color_riser"
                                    value={basicInformation.color_riser}
                                >
                                    <option value="">Selecteer optie</option>
                                    {selectedMaterial &&
                                        selectedMaterial.colorrisers.map(
                                            (o, k) => {
                                                return (
                                                    <option
                                                        value={o.id}
                                                        key={k}
                                                    >
                                                        {o.name}
                                                    </option>
                                                );
                                            }
                                        )}
                                </select>
                            </div>
                            <div className="col-6">
                                <p className="fw-bold mb-0 mt-2">Aantal</p>
                                <input
                                    type="number" min="1"
                                    placeholder="Aantal"
                                    name="color_riser_nr"
                                    className="form-control"
                                    value={basicInformation.numbers && basicInformation.numbers.find(a => a.name == 'color_riser') ? basicInformation.numbers.find(a => a.name == 'color_riser').value : ''}
                                    onChange={(e) =>
                                        updateObjectNumbers(
                                            "color_riser",
                                            e.target.value
                                        )
                                    }
                                />
                            </div>
                        </div>
                    </div>
                    <div className="question-group col-12">
                        <div className="row">
                        <div className="col-12">
                                <p className="fw-900 fs-4 mb-0 mt-2">
                                Uitlooptrede
                                </p>
                            </div>
                            <div className="col-6">
                                <p className="fw-bold mb-0 mt-2">
                                    Type
                                </p>
                                <select
                                    className="form-control"
                                    name="exit_step"
                                    value={basicInformation.exit_step}
                                >
                                    <option value="">Selecteer optie</option>
                                    {selectedMaterial &&
                                        selectedMaterial.exitsteps.map(
                                            (o, k) => {
                                                return (
                                                    <option
                                                        value={o.id}
                                                        key={k}
                                                    >
                                                        {o.name}
                                                    </option>
                                                );
                                            }
                                        )}
                                </select>
                            </div>
                            <div className="col-6">
                                <p className="fw-bold mb-0 mt-2">Aantal</p>
                                <input
                                    type="number" min="1"
                                    placeholder="Aantal"
                                    name="exit_step_nr"
                                    className="form-control"
                                    value={basicInformation.numbers && basicInformation.numbers.find(a => a.name == 'exit_step') ? basicInformation.numbers.find(a => a.name == 'exit_step').value : ''}
                                    onChange={(e) =>
                                        updateObjectNumbers(
                                            "exit_step",
                                            e.target.value
                                        )
                                    }
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                                <p className="fw-900 fs-4 mb-0 mt-2">
                                Wangen
                                </p>
                            </div>
                    <div className="question-group col-12">
                        <p className="fw-bold mb-0 mt-2">Zijn deze gewenst?</p>
                        <div
                            className="btn-group w-100"
                            role="group"
                            aria-label="Basic radio toggle button group"
                        >
                            <input
                                type="radio"
                                className="btn-check"
                                value="ja"
                                name="cheeks"
                                id={"wangen1"+variantTab}
                                autoComplete="off"
                                defaultChecked={basicInformation.cheeks == 'ja'}
                            />
                            <label
                                className="btn btn-outline-primary"
                                htmlFor={"wangen1"+variantTab}
                            >
                                Ja
                            </label>
                            <input
                                type="radio"
                                className="btn-check"
                                value="nee"
                                name="cheeks"
                                id={"wangen2"+variantTab}
                                autoComplete="off"
                                defaultChecked={basicInformation.cheeks == 'nee' || basicInformation.cheeks == '' || basicInformation.cheeks == null}
                            />
                            <label
                                className="btn btn-outline-primary"
                                htmlFor={"wangen2"+variantTab}
                            >
                                Nee
                            </label>
                        </div>
                    </div>

                    <div
                        className={
                            "question-group col-12 " +
                            (formData.cheeks !== null &&
                            formData.cheeks !== "" &&
                            formData.cheeks !== "nee"
                                ? "d-block"
                                : "d-none")
                        }
                    >
                        <div className="row">
                            <div className="col-6">
                                <p className="fw-bold mb-0 mt-2">
                                    Type
                                </p>
                                <select
                                    className="form-control"
                                    name="color_cheeks"
                                >
                                    <option value="">Selecteer optie</option>
                                    {selectedMaterial &&
                                        selectedMaterial.cheeks.map((o, k) => {
                                            return (
                                                <option value={o.id} key={k}>
                                                    {o.name}
                                                </option>
                                            );
                                        })}
                                </select>
                            </div>
                            <div className="col-6">
                                <p className="fw-bold mb-0 mt-2">Aantal</p>
                                <input
                                    type="number" min="1"
                                    className="form-control"
                                    name="cheeks_nr"
                                    placeholder="Aantal"
                                    onChange={(e) =>
                                        updateObjectNumbers(
                                            "color_cheeks",
                                            e.target.value
                                        )
                                    }
                                />
                            </div>
                        </div>
                    </div>

                    <div className={
                            "question-group col-12 " +
                            (formData.cheeks !== null &&
                            formData.cheeks !== "" &&
                            formData.cheeks !== "nee"
                                ? "d-block"
                                : "d-none")
                        }>
                        <div className="row">
                        <div className="col-12">
                                <p className="fw-900 fs-4 mb-0 mt-2">
                                L profiel
                                </p>
                            </div>
                            <div className="col-6">
                                <p className="fw-bold mb-0 mt-2">
                                   Type
                                </p>
                                <select
                                    className="form-control"
                                    name="color_l_u_profile"
                                >
                                    <option value="">Selecteer optie</option>
                                    {selectedMaterial &&
                                        selectedMaterial.landus.map((o, k) => {
                                            return (
                                                <option value={o.id} key={k}>
                                                    {o.name}
                                                </option>
                                            );
                                        })}
                                </select>
                            </div>
                            <div className="col-6">
                                <p className="fw-bold mb-0 mt-2">Aantal</p>
                                <input
                                    type="number" min="1"
                                    className="form-control"
                                    name="l_profile_nr"
                                    placeholder="Aantal"
                                    onChange={(e) =>
                                        updateObjectNumbers(
                                            "l_profile",
                                            e.target.value
                                        )
                                    }
                                />
                            </div>
                        </div>
                    </div>
                    <div className={
                            "question-group col-12 " +
                            (formData.cheeks !== null &&
                            formData.cheeks !== "" &&
                            formData.cheeks !== "nee"
                                ? "d-block"
                                : "d-none")
                        }>
                        <div className="row">
                        <div className="col-12">
                                <p className="fw-900 fs-4 mb-0 mt-2">
                                U profiel
                                </p>
                            </div>
                            <div className="col-6">
                                <p className="fw-bold mb-0 mt-2">
                                    Type
                                </p>
                                <select className="form-control" name="">
                                <option value="">Selecteer optie</option>
                                    {selectedMaterial &&
                                        selectedMaterial.landus.map((o, k) => {
                                            return (
                                                <option value={o.id} key={k}>
                                                    {o.name}
                                                </option>
                                            );
                                        })}
                                </select>
                            </div>
                            <div className="col-6">
                                <p className="fw-bold mb-0 mt-2">Aantal</p>
                                <input
                                    type="number" min="1"
                                    className="form-control"
                                    placeholder="Aantal"
                                    name="u_profile_nr"
                                    onChange={(e) =>
                                        updateObjectNumbers(
                                            "u_profile",
                                            e.target.value
                                        )
                                    }
                                />
                            </div>
                        </div>
                    </div>
                    <div className="question-group col-6 my-3">
                        <p className="fw-900 fs-4 mb-0 mt-2">Accessoires</p>
                 
                        <ul className="list-group">
                            {accessoires.sort((a, b) => a.order - b.order).map((o, k) => {
                                return (
                                    <li className="list-group-item" key={k}>
                                        <label>
                                            <input
                                                type="checkbox"
                                                className="me-2"
                                                defaultChecked={exists(
                                                    o,
                                                    selectedAccessoires
                                                )}
                                                onChange={(e) =>
                                                    changeAccessoires(e, o)
                                                }
                                            />
                                            {o.name}
                                        </label>
                                        {exists(o, selectedAccessoires) && (
                                            <div className="form-floating">
                                                <input
                                                    placeholder="Aantal"
                                                    type="number" min="1"
                                                    className="form-control"
                                                    name={'acc'+k}
                                                    defaultValue={getNumber(o,selectedAccessoires) !== null ? getNumber(o,selectedAccessoires) : 1}
                                                    onChange={(e) =>
                                                        updateNumbers(
                                                            e.target.value ? e.target.value : 1,
                                                            o,
                                                            "accessoires"
                                                        )
                                                    }
                                                />
                                                <label>Aantal</label>
                                            </div>
                                        )}
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                    <div className="question-group col-6 my-3">
                        <p className="fw-900 fs-4 mb-0 mt-2">Arbeid</p>
                        <ul className="list-group">
                            {labors.sort((a, b) => a.order - b.order).map((o, k) => {
                                return (
                                    <li className="list-group-item" key={k}>
                                        <label>
                                            <input
                                                type="checkbox"
                                                className="me-2"
                                                defaultChecked={exists(
                                                    o,
                                                    selectedLabors
                                                )}
                                                onChange={(e) =>
                                                    changeLabors(e, o)
                                                }
                                            />
                                            {o.name}
                                        </label>
                                        {exists(o, selectedLabors) && (
                                            <div className="form-floating">
                                                <input
                                                    placeholder="Aantal"
                                                    type="number" min="1"
                                                    name={'labor'+k}
                                                    className="form-control"
                                                    defaultValue={getNumber(o,selectedLabors) !== null ? getNumber(o,selectedLabors) : 1}
                                                    onChange={(e) =>
                                                        updateNumbers(
                                                            e.target.value ? e.target.value : 1,
                                                            o,
                                                            "labors"
                                                        )
                                                    }
                                                />
                                                <label>Aantal</label>
                                            </div>
                                        )}
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                    <div className="question-group col-12">
                        <p className="fw-900 fs-4 mb-0 mt-2">Overige opmerkingen</p>
                        <textarea className="form-control" name="extra_description" value={basicInformation.extra_description} placeholder="Vul hier overige opmerkingen mits van toepassing"></textarea>
                    </div>
                        </>
                    }
                    
                    </form>
                    <div className="col-12">
      
                    {  basicInformation.extra_stairs && basicInformation.extra_stairs.length > 0 &&
                        basicInformation.extra_stairs.map((o,k) => {
                            return (
                                <div key={k+'extrastairs'}>
                                   <ExtraBasicInformation
                                    data={basicInformation.extra_stairs[k]}
                                    materials={materials}
                                    dataLabors={labors}
                                    dataAccessoires={accessoires}
                                    updateStairs={(data,k) => updateStairs(data,k)}
                                    removeStairs={() => removeStairs()}                                
                                    index={k}     
                                    variantTab={variantTab}            
                                    syncMode={syncMode}
                                    setSyncMode={setSyncMode}                             
                                   />
                                </div>
                            )
                        })
                    }
            
                    </div>

                    { selectedMaterial &&
                        <div className="col-12">
                        <div className="row">
                        <div className="col-6 mt-3">
                    <button
                            type="button"
                            className="btn btn-secondary w-100"
                            onClick={addNewStairs}
                        >
                            Nieuwe trap toevoegen <PlusCircle size={14} />
                        </button>
                    </div>
                    <div className="col-6 mt-3">
                        <button
                            type="button"
                            className="btn btn-primary w-100"
                            onClick={() => goToStep("finish")}
                        >
                            Volgende stap <ArrowRight size={14} />
                        </button>
                    </div>
                    
                        </div>
                        
                    </div>
                    }
             
                </div>
                      }
           
        </>
          
    );
};

export default BasicInformation;
