import {
    PencilCircle,
    PencilSimple,
    PlusCircle,
    TrashSimple,
} from "phosphor-react";
import { useEffect, useRef, useState } from "react";
import Finish from "../Finish";

const FinishTabs = (props) => {

    const {order, goToStep, finishStep, basicInformation, client, setBasicInformation, setClient, tabs, setTab, saveQuotation, quotationSaving} = props;


  

    const [selectedTab, setSelectedTab] = useState(null);

    const addTab = (e) => {
        let obj = { name: "Variant name", selected: true };
        // setTab(tabs => [...tabs, obj]);
        setTab((prevState) => {
            const newState = prevState.map((obj, k) => {
                // 👇️ if id equals 2, update country property

                return { ...obj, selected: false };
            });

            return [...newState, obj];
        });
    };

    const updateBasicInformation = (data,i) => {
        console.log('save stair', i , data)

        const updated_data = basicInformation.map((c,index) => {
            if(i === index) {
                return data;
            } else {
                return c
            }
        })

        setBasicInformation((prev) => updated_data)

        console.log('basic',i,data,basicInformation)
    }


    const selectTab = (i) => {
        setTab((prevState) => {
            const newState = prevState.map((obj, k) => {
                // 👇️ if id equals 2, update country property
                if (k === i) {
                    return { ...obj, selected: true };
                } else {
                    return { ...obj, selected: false };
                }
            });

            return newState;
        });
    };

    const deleteTab = (index) => {
        setTab([...tabs.slice(0, index), ...tabs.slice(index + 1)]);
    };

    const editTab = (index, obj) => {
        obj = {...obj, index: index}
        setSelectedTab(obj);
    };

    const saveTab = (e) => {
        e.preventDefault()
        let a = selectedTab;
        let form = new FormData(e.target)
        setTab((prevState) => {
            const newState = prevState.map((obj, k) => {
                // 👇️ if id equals 2, update country property
                if (k === a.index) {
                    return { ...obj, name: form.get('name') };
                } else {
                    return obj
                }
            });

            return newState;
        });
        setSelectedTab(null)
    }

    return (
        <>

{selectedTab !== null && (
                <>
                    <div
                        style={{
                            position: "fixed",
                            background: "#fff",
                            width: "400px",
                            left:'50%',
                            marginLeft:'-200px',
                            height: "auto",
                            borderRadius: "10px",
                            zIndex: 9999,
                            padding: "15px",
                        }}  
                    >
                        <form onSubmit={(e) => saveTab(e)}>
                            <p className="fw-bold">Wijzig variant naam</p>
                            <input defaultValue={selectedTab && selectedTab.name} name="name" className="form-control"/>
                            <button type="submit" className="btn btn-primary w-100 mt-2">Opslaan</button>
                        </form>
                    </div>
                    <div
                        style={{
                            position: "fixed",
                            background: "#000",
                            opacity: 0.5,
                            zIndex: 888,
                            left: 0,
                            right: 0,
                            top: 0,
                            bottom: 0,
                        }}
                        onClick={(e) => setSelectedTab(null)}
                    ></div>
                </>
            )}

            {tabs && 
                    tabs.map((t, k) => {
                        if(basicInformation.length > 0)
                        return (
            <div key={k} className={
                "variant-tab-content" +
                (t.selected ? " selected" : "")
            }>
                           
                            <Finish 
                                variantTab={k}
                                order={order}
                                goToStep={goToStep}
                                finishStep={finishStep}
                                basicInformation={basicInformation[k]}
                                client={client}
                                setBasicInformation={(data,k) => updateBasicInformation(data,k)}
                                setClient={setClient}
                                saveQuotation={saveQuotation}
                                quotationSaving={quotationSaving}
                            />
            </div>
    )
}) }

            
        </>
    );
};

export default FinishTabs;
