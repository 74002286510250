import axios from "axios";
import { CheckCircle, FloppyDisk } from "phosphor-react";
import { useEffect, useMemo, useRef, useState } from "react";
import ExtraFinish from "./ExtraFinish";
import SignatureCanvas from 'react-signature-canvas'
import { Envelope, MapPin } from "@phosphor-icons/react";


const Finish = (props) => {
    const { variantTab, order, goToStep, finishStep, basicInformation, client, setBasicInformation, setClient ,saveQuotation, quotationSaving } = props;
    const [total, setTotal] = useState(0)
    const [discount, setDiscount] = useState('')
    const [finished, setFinished] = useState(false)
    const [finalMode, setFinalMode] = useState(false)

    const [onLocation, setOnLocation] = useState(true);

    const [montageDate, setMontageDate] = useState(null)
    const [sendEmail, setSendEmail] = useState(null)

    const [finishingLoading, setFinishingLoading] = useState(false);

    const [signature, setSignature] = useState(null)
    let canvas = useRef();

    const moneyFormat = (nr) => {
       return nr.toLocaleString('nl-NL', { style: 'currency', currency: 'EUR' })
    }

    const capitalizeString = (str) => {
        if(str !== ''){
            const lower = str.toLowerCase();
            return str.charAt(0).toUpperCase() + lower.slice(1)
        } else {
            return ''
        }
    }

    const getTotal = () => {
        const discountValue = discount === '' ? 0 : parseInt(discount); // Convert to 0 if empty
        axios.post('/sales/calculate', {
            order: order,
            basicInformation: basicInformation,
            client: {id: client.id, customer_id : client.customer_id, sales_id: client.sales_id},
            discount: discountValue
        }).then((response) => {
            console.log('response', response);
            setTotal(response.data);
        });
    }
    

    const getTotalPrize = () => {
        return total;
    }

    const generateExamplePDF = () => {
        axios.post('/sales/generate-pdf',{
            order: order,
            basicInformation: basicInformation,
            client: client,
            discount: discount,
        }, {
            responseType: 'blob'
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Offerte.pdf'); //or any other extension
            document.body.appendChild(link);
            link.click();
        })
    }

    const sendQuotationNow = () => {
        console.log('send now')
        saveQuotation()
        axios.post('/sales/send-quotation',{
            order: order,
            basicInformation: basicInformation,
            client: client,
            discount: discount,
        }).then((response) => {
            console.log('response',response)
            setFinished(true)
            setTimeout(() => {      
                location.reload();             
            }, 4000)
        })
    }

    const sendNow = () => {
        console.log('send now',canvas.getTrimmedCanvas().toDataURL('image/png'),client)

        if (canvas.isEmpty()) {
            alert('Handtekening is leeg!');
            return;
        }

        if(montageDate == null || montageDate == ''){
            alert('Geen montage datum!');
            return; 
        }
        setFinishingLoading(true)
        axios.post('/sales/finish',{
            order: order,
            basicInformation: basicInformation,
            client: client,
            discount: discount,
            montageDate: montageDate,
            signature: canvas.getTrimmedCanvas().toDataURL('image/png')
        }).then((response) => {
            console.log('response',response)
            setFinishingLoading(false)
            setFinished(true)
            setTimeout(() => {      
                location.reload();             
            }, 4000)
        })
    }

    const sendNowByMail = () => {



        if(montageDate == null || montageDate == ''){
            alert('Geen montage datum!');
            return; 
        }

        

        if(sendEmail == null || sendEmail == ''){
            setSendEmail(client.customer.email)
        }

        setFinishingLoading(true)

        axios.post('/sales/finish-by-mail',{
            order: order,
            basicInformation: basicInformation,
            client: client,
            discount: discount,
            montageDate: montageDate,
            sendEmail: sendEmail
        }).then((response) => {
            console.log('response',response)
            setFinishingLoading(false)
            setFinished(true)
            setTimeout(() => {      
                location.reload();             
            }, 4000)
        })
    }

    const getInvoiceTotal = () => {
        let nr = 0;
    }

    const getNumberOfProduct = (name, stairIndex = null) => {
        if (stairIndex !== null) {
            if (basicInformation.extra_stairs[stairIndex] && basicInformation.extra_stairs[stairIndex].numbers) {
                let find = basicInformation.extra_stairs[stairIndex].numbers.find(a => a.name === name);
                if (find) {
                    return find.value;
                }
            }
        } else {
            if (basicInformation.numbers) {
                let find = basicInformation.numbers.find(a => a.name === name);
                if (find) {
                    return find.value;
                }
            }
        }
        return 0;
    };
    

    const getQuantity = (type, name) => {
        let value = 0;

        if (type == 'accessoires') {
            basicInformation.numbers && basicInformation.numbers.accessoires && basicInformation.numbers.accessoires.find(o => {
                if (o.name === name)  {
                    console.log(o.value)
                    value = parseInt(o.value)
                }
            })
        } else {
            basicInformation.numbers && basicInformation.numbers.labors && basicInformation.numbers.labors.map(o => {
                if (o.name === name)  {
                    console.log(o.value)
                    value = parseInt(o.value)
                }
            })
        }
        return parseInt(value);
    }

    useEffect(() => {
        if(client && client.hasOwnProperty('customer')){
            setSendEmail(client.customer.email); 
        }
    }, [client]);

    useEffect(() => {
        if (finishStep) {
            getTotal();
        }
    }, [finishStep]);

    useEffect(() => {
        const discountValue = discount === '' ? 0 : parseInt(discount);
        if (discountValue !== 0) {
            console.log(basicInformation, discountValue);
            let tmp = { ...basicInformation, discount: discountValue };
            console.log(tmp);
            setBasicInformation(tmp, variantTab);
            getTotal();
        } else {
            // When discount is 0 or empty, remove discount from basicInformation
            let tmp = { ...basicInformation };
            delete tmp.discount;
            setBasicInformation(tmp, variantTab);
            getTotal();
        }
    }, [discount]);
    

    useEffect(() => {
        if (basicInformation.hasOwnProperty('discount') && basicInformation.discount !== 0 && discount == 0) {
            setDiscount(basicInformation.discount)
        }
    }, [basicInformation.discount]);

    // Synchronize basicInformation state
    useEffect(() => {
        setBasicInformation(basicInformation, variantTab);
    }, [basicInformation, variantTab]);

    return (
<>

{ quotationSaving &&
    <div className="finished-screen d-flex text-center" style={{
        position:'fixed',
        zIndex:'99',
        width:'100%',
        height:'100%',
        top:0,
        left:0,
        right:0,
        bottom:0,
        background:'green',
        color:'#fff'
    }}>
        <div className="align-self-center w-100 text-center">
        <CheckCircle size={65} fill="bold" color={'#fff'} />
        <p>Succesvol opgeslagen!</p>
        </div>
    </div>
}

{ finalMode &&
<>
<div
style={{
    position: "fixed",
    background: "#fff",
    width: "400px",
    left:'50%',
    marginLeft:'-200px',
    height: "auto",
    borderRadius: "10px",
    zIndex: 9999,
    padding: "15px",
}}  
>

<div className="col-12 p-3">
<h2 style={{ fontWeight: 700, textAlign: 'center', marginBottom: '20px'}}>Opdrachtbevestiging</h2>
<ul class="nav nav-pills d-flex text-center flex-nowrap align-items-center bg-light mb-4">
  <li class="nav-item w-100">
    <a className={onLocation ? 'nav-link active' : 'nav-link'} onClick={() => setOnLocation(true)}><MapPin size={18}/> Op locatie</a>
  </li>
  <li class="nav-item w-100">
    <a className={!onLocation ? 'nav-link active' : 'nav-link'} onClick={() => setOnLocation(false)}><Envelope size={18}/> Per mail (online)</a>
  </li>
</ul>
    <div className={!onLocation ? 'd-block' : 'd-none'}>  
        <label>Datum van uitvoering</label>
        <input type="date" className="form-control mb-2" onChange={(e) => setMontageDate(e.target.value)} value={montageDate}/>
        <label>Email</label>
        <input type="email" className="form-control mb-2" onChange={(e) => setSendEmail(e.target.value)} value={sendEmail}/>
        <button type="button" onClick={() => sendNowByMail()} className='btn btn-primary btn-lg w-100 align-self-center' disabled={finishingLoading}>{!finishingLoading ? 'Afronden' : 'Verwerken...'}</button>

    </div>
    <div className={onLocation ? 'd-block' : 'd-none'}>        
    <label>Datum van uitvoering</label>
    <input type="date" className="form-control mb-2" onChange={(e) => setMontageDate(e.target.value)} value={montageDate}/>
    <label className="fw-bold mb-2">Uw handtekening:</label>
<SignatureCanvas penColor='blue' backgroundColor='transparent'
canvasProps={{width: '340', height: '200', className: 'sigCanvas'}}
ref={(ref) => { canvas = ref }}
/>
<button type="button" className="btn btn-outline-secondary w-100 btn-sm fw-400" onClick={() => canvas.clear()}>Wissen</button>
<div className="col-12 d-flex flex-column">
    <p class="small text-muted"><label><input type="checkbox" name="agree" checked required/> Ik ga akkoord met de algemene voorwaarden van Traprenovatie Nederland</label></p>
<button type="button" onClick={() => sendNow()} className='btn btn-primary btn-lg w-100 align-self-center' disabled={canvas.isEmpty || finishingLoading ? true : false}>{!finishingLoading ? 'Afronden' : 'Verwerken...'}</button>
</div>
</div>

<div className="col-12">
<button type="button" onClick={() => setFinalMode(false)} className='btn btn-light w-100'>Annuleren</button>
</div>
    </div>
</div>
<div style={{
    position: "fixed",
    background: "#000",
    width: "100%",
    left:'0',    
    right:0,
    top:0,
    bottom:0,
    height: "100%",
    opacity:0.5,
    zIndex: 888,
    padding: "15px",
}}  
onClick={() => setFinalMode(false)}
>

</div>
</>

}

{ finished &&
    <div className="finished-screen d-flex text-center" style={{
        position:'fixed',
        zIndex:99999,
        width:'100%',
        height:'100%',
        top:0,
        left:0,
        right:0,
        bottom:0,
        background:'green',
        color:'#fff'
    }}>
        <div className="align-self-center w-100 text-center">
        <CheckCircle size={65} fill="bold" color={'#fff'} />
        <p>Succesvol afgerond!</p>
        </div>
    </div>
}

                       <h3 className="fw-bold">Offerte afronden</h3>

            {order && (
                <div className="card mt-4">
                    <div className="card-body">
                    <p className="mb-0 fw-bold">
                        {order.order_data.customer.firstname}{" "}
                        {order.order_data.customer.lastname}
                    </p>
                    <p className="mb-0">
                        {order.order_data.customer.street}{" "}
                        {order.order_data.customer.number}
                        {order.order_data.customer.addition
                            ? order.order_data.customer.addition
                            : ""}
                    </p>
                    <p className="mb-0">
                        {order.order_data.customer.zipcode},{" "}
                        {order.order_data.customer.place}
                    </p>
                    </div>
                </div>
            )}
            { basicInformation && basicInformation.hasOwnProperty('numbers') &&
                <div className="card border-0 mt-3">
                    <div className="card-body p-0">
                    <h4 style={{background:'#212529', color:'#fff', borderTopRightRadius:'5px', borderTopLeftRadius:'5px'}}> {basicInformation.stairs_description}</h4>
                        <ul className="list-group rounded-0">                          
                            <li className="list-group-item">                               
                                <div className="row">
                                    <div className="col-3">
                                        <p className="m-0 fw-bold">Type</p>
                                        {basicInformation.type && basicInformation.type == 'no' ? 'Dicht' : 'Open'} 
                                    </div>
                                    <div className="col-3 text-start">
                                    <p className="m-0 fw-bold">Model</p>
                                    {basicInformation.model} 
                                    </div>
                                    <div className="col-3 text-start">
                                    <p className="m-0 fw-bold">Materiaal</p>
                                        {basicInformation.selectedMaterial && basicInformation.selectedMaterial.name}                                     
                                    </div>
                                                                 
                                    <div className="col-3 text-start">
                                    <p className="m-0 fw-bold">Kleur trede</p>
                                        {basicInformation.selectedMaterial && basicInformation.color_step !== "" && basicInformation.selectedMaterial.colors.find(a => a.id == basicInformation.color_step) && basicInformation.selectedMaterial.colors.find(a => a.id == basicInformation.color_step).name}
                                        {basicInformation.color_step == "" &&
                                            (
                                                <p className="alert p-1 bg-danger text-white">Let op, geen kleur!</p>
                                            )
                                        }
                                    </div>
                                  
                                </div>
                            </li>                           
                           
                            <li className="list-group-item">
                                <div className="row">
                                    <div className="col-12">
                                    <p className="m-0 fw-bold">Overzettreden</p>
                                    </div>
                                </div>
                                { basicInformation.transfer_steps && basicInformation.transfer_steps.map((o,k) =>{

                                    return (
                                        <div className="row" key={k}>
                                    <div className="col-6"> 
                                        <p className="mb-0 fw-bold">{o.name}</p>
                                        <small className="d-block text-muted">Aantal: {o.number}</small>
                                    </div>
                                    <div className="col-6 text-end">
                                     {moneyFormat(o.number * o.price)}
                                    </div>
                                </div>
                                    )
                                })

                                }
                            </li>

                            <li className="list-group-item">
                                <div className="row">
                                    <div className="col-6">
                                    <p className="mb-0 fw-bold">Stootbord</p>
                                    {basicInformation.selectedMaterial && basicInformation.color_riser !== "" && basicInformation.color_riser !== null && basicInformation.selectedMaterial.colorrisers.find(a => a.id == basicInformation.color_riser).name}
                                    <small className="d-block text-muted">Aantal: {getNumberOfProduct('color_riser')}</small>
                                    </div>
                                    
                                    <div className="col-6 text-end">
                                    {basicInformation.numbers && basicInformation.selectedMaterial && basicInformation.color_riser !== "" && basicInformation.color_riser !== null && moneyFormat(basicInformation.selectedMaterial.colorrisers.find(a => a.id == basicInformation.color_riser).price * getNumberOfProduct('color_riser'))}
                                    </div>
                                </div>
                            </li>
                            <li className="list-group-item">
                                <div className="row">
                                    <div className="col-6">
                                    <p className="m-0 fw-bold">Uitlooptrede</p>
                                    {basicInformation.selectedMaterial && basicInformation.exit_step !== "" && basicInformation.exit_step !== null && basicInformation.selectedMaterial.exitsteps.find(a => a.id == basicInformation.exit_step).name}
                                    <small className="d-block text-muted">Aantal:{getNumberOfProduct('exit_step')}</small>
                                    </div>
                                    <div className="col-6 text-end">
                                    {basicInformation.numbers && basicInformation.selectedMaterial && basicInformation.selectedMaterial && basicInformation.exit_step !== "" && basicInformation.exit_step !== null && moneyFormat(basicInformation.selectedMaterial.exitsteps.find(a => a.id == basicInformation.exit_step).price * getNumberOfProduct('exit_step'))}
                                    </div>
                                </div>
                            </li>
                            {/* <li className="list-group-item">
                                <div className="row">
                                    <div className="col-6">
                                    <p className="m-0 fw-bold">Wangen</p>
                                    {basicInformation.cheeks}
                                 
                                    </div>
                                    <div className="col-6 text-end">

                                    </div>
                                </div>
                            </li> */}
                            { basicInformation.cheeks !== null && basicInformation.cheeks !== "nee" &&
                                <li className="list-group-item">
                                <div className="row">
                                    <div className="col-6">
                                    <p className="m-0 fw-bold">Kleur Wangen</p>
                                    {basicInformation.selectedMaterial && basicInformation.cheeks !== 'nee' && basicInformation.color_cheeks !== "" && basicInformation.color_cheeks !== null && basicInformation.selectedMaterial.cheeks.find(a => a.id == basicInformation.color_cheeks).name}
                                    <small className="d-block text-muted">Aantal: {getNumberOfProduct('color_cheeks')}</small>
                                    </div>
                                    <div className="col-6 text-end">
                                    {basicInformation.numbers && basicInformation.cheeks !== 'nee' && basicInformation.selectedMaterial && basicInformation.color_cheeks !== "" && basicInformation.color_cheeks !== null && moneyFormat(basicInformation.selectedMaterial.cheeks.find(a => a.id == basicInformation.color_cheeks).price * getNumberOfProduct('color_cheeks'))}
                                    </div>
                                </div>
                            </li>   
                            }   
                               { basicInformation.cheeks !== null && basicInformation.cheeks !== "nee" &&         
                            <li className="list-group-item">
                                <div className="row">
                                    <div className="col-6">
                                    <p className="m-0 fw-bold">Kleur L profiel en U profiel</p>
                                    {basicInformation.selectedMaterial && basicInformation.color_l_u_profile !== "" && basicInformation.selectedMaterial.landus.find(a => a.id == basicInformation.color_l_u_profile).name}
                                    <small className="d-block text-muted">Aantal: {getNumberOfProduct("l_profile")}</small>
                                    </div>
                                    <div className="col-6 text-end">
                                    {basicInformation.numbers && basicInformation.selectedMaterial && basicInformation.color_l_u_profile !== "" &&  moneyFormat(basicInformation.selectedMaterial.landus.find(a => a.id == basicInformation.color_l_u_profile).price * getNumberOfProduct("l_profile"))}
                                    </div>
                                </div>
                            </li>
                            }
                        </ul>
                       
                        
                           {/* <p className="mb-0">
                           <strong>Kleur trede:</strong> {basicInformation.color_step}
                           </p>
                           <p className="mb-0">
                           <strong>Kleur stootbord:</strong> {basicInformation.color_riser}
                           </p>
                           <p className="mb-0">
                           <strong>Uitlooptrede:</strong> {basicInformation.exit_step}
                           </p>
                           <p className="mb-0">
                           <strong>Wangen:</strong> {basicInformation.cheeks}
                           </p>
                           <p className="mb-0">
                           <strong>Kleur wangen:</strong> {basicInformation.color_cheeks}
                           </p>
                           <p className="mb-0">
                           <strong>Kleur L profiel en U profiel:</strong> {basicInformation.color_l_u_profile}
                           </p>                      */}
                           <h4 className="py-2">Accessoires</h4>
                           <ul className="list-group rounded-0">
                           { basicInformation.selectedAccessoires && basicInformation.selectedAccessoires.sort((a, b) => a.name.localeCompare(b.name)).map((a,k) => {
                            return (
                                <li className="list-group-item" key={k}>
                                    <div className="row">
                                        <div className="col-6">
                                        <p className="m-0 fw-bold">{a.name}</p>
                                        <small className="d-block text-muted">Aantal: {a.number}</small>
                                        </div>
                                        <div className="col-6 text-end">
                                        <p className="m-0">{moneyFormat(parseInt(a.price) * a.number)}</p>                               
                                        </div>
                                    </div>
                                </li>
                            )
                           })
                           }
                           </ul>
                           { basicInformation.selectedLabors && basicInformation.selectedLabors.length > 0 &&
                           <>
                           <h4 className="py-2">Arbeid</h4>

<ul className="list-group rounded-0">
{ basicInformation.selectedLabors && basicInformation.selectedLabors.sort((a, b) => a.name.localeCompare(b.name)).map((a,k) => {
 return (
     <li className="list-group-item" key={k}>
         <div className="row">
         <div className="col-6">
             <p className="m-0 fw-bold">{a.name}</p>
             <small className="d-block text-muted">Aantal: {a.number}</small>
             </div>
             <div className="col-6 text-end">
             <p className="m-0">{moneyFormat(parseInt(a.price) * a.number)}</p>
             
             </div>
         </div>
     </li>
 )
})
}

</ul>
</>
                           }
                           { basicInformation.extra_description && basicInformation.extra_description.length >= 1 &&
                            <>
                             <h4 className="py-2">Overige opmerkingen</h4>
                             <ul className="list-group rounded-0">
                             <li className="list-group-item">
                             <p className="mb-0" style={{whiteSpace:'pre-line'}}>{basicInformation.extra_description}</p>

                                </li>
                                </ul>
                            </>
                           }
{/*                         
                           {    basicInformation.extra_stairs && basicInformation.extra_stairs.length > 0 &&
                            <div className="row">                          
                            <div className="col-12">
                                <p className="m-0 fw-bold">Extra trappen</p>
                                <small className="d-block text-muted">Aantal: {basicInformation.extra_stairs.length}</small>
                            </div>                            
                            </div>
                           } */}

                           { basicInformation.extra_stairs && basicInformation.extra_stairs.length > 0 && basicInformation.extra_stairs.map((o,k) =>{
                            return (
                               <ExtraFinish basicInformation={o} key={k}/>
                                )
                           })

                           }
                           
                           <div className="row" style={{marginTop:'150px'}}>
                            <div className="col-12">
                                <ul className="list-group rounded-0">
                                    <li className="list-group-item">
                                    <p className="m-0 fw-bold">Korting</p>
                                    <div className="input-group">
                                    <span className="input-group-text">€</span>
                                    <input type="number" min="0" className="form-control" value={discount} onChange={(e) => setDiscount(e.target.value)} />
                                    </div>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-12">
                                <ul className="list-group rounded-0">
                                    <li className="list-group-item">
                                        <div className="row fs-5">
                                            <div className="col-6">
                                                <p className="mb-0 fw-bold">Totaal</p>
                                            </div>
                                            <div className="col-6 text-end">
                                                <p className="mb-0 fs-3 fw-bold">{moneyFormat(total)}</p>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            { finalMode ?
                                    <>
                                    
                                    </>
                                    :
                                    <>
                                    <div className="col-6">
                                    <button type="button" onClick={() => generateExamplePDF()} className='btn btn-outline-secondary mt-3 w-100 btn-lg'>                                                
                                                Offerte voorbeeld
                                                </button>
                                            <button type="button" onClick={() => saveQuotation()} className='btn btn-secondary mt-3 w-100 btn-lg'>
                                                <FloppyDisk size={21} weight="bold" style={{position:'relative', top:'-2px', marginRight:'4px'}}/>
                                                Offerte opslaan
                                                </button>
                                               
                                    </div>
                                    <div className="col-6">
                                            <button type="button" onClick={() => sendQuotationNow()} className='btn btn-outline-primary mt-3 w-100 btn-lg'>Afronden, offerte uitsturen</button>
                                            <button type="button" onClick={() => setFinalMode(true)} className='btn btn-primary mt-3 w-100 btn-lg'>Afronden, opdrachtbevestiging</button>
                                    </div>
                                    </>
                            }
                            
                           </div>
                    </div>
                </div>
            }
           
       </>
    );
};

export default Finish;
