import { ArrowRight, PlusCircle } from "phosphor-react";
import { useEffect, useMemo, useState, useRef } from "react";
import { syncData } from "./utils/SyncData";

const ExtraBasicInformation = (props) => {
    const { data, materials, dataLabors, dataAccessoires,  updateStairs, removeStairs, index, variantTab, syncMode, setSyncMode} = props;

    
    let form = useRef(null)
    const [sync, setSync] = useState(false);

    const [selectedMaterial, setSelectedMaterial] = useState(null);
    const [selectedAccessoires, setSelectedAccessoires] = useState([]);
    const [selectedLabors, setSelectedLabors] = useState([]);

    const [debouncedAccessoires, setDebouncedAccessoires] = useState(selectedAccessoires);
    const [debouncedLabors, setDebouncedLabors] = useState(selectedLabors);

    const [formData, setFormData] = useState(() => ({
        stairs_description: null,
        type: null,
        model: null,
        material: null,
        color_step: null,
        color_riser: null,
        exit_step: null,
        transfer_step: null,
        cheeks: 'nee',
        color_cheeks: null,
        color_l_u_profile: null,
        extra_description: null,
        selectedMaterial: null,
        transfer_steps: [{}],
        numbers: [],
    }));

    const handleSubmit = (e) => {
        e.preventDefault();
    };

       
    const changeSelectedMaterial = (e) => {
        const selected = materials.find((a) => a.id == e);
        console.log('change',materials,selected)
        setSelectedMaterial(selected);
        //console.log(selected);
        console.log('change',materials,selected,selectedMaterial)
    };

    const headingStyle = {
        margin: '0 -15px 0 -15px',
        padding: '16px',
        backgroundColor: '#eee',
        marginBottom:'2px'
    }

    const addTransferStep = (e) => {
        e.preventDefault()
        let tmp = formData;
        tmp.transfer_steps.push({});
        // // console.log(tmp)
        setFormData((a) => {
            return { ...a, transfer_steps: tmp.transfer_steps };
        });
        updateStairs(formData,index);
    };

    const updateStairDescription = (e) => {
        setFormData((a) => {
            return { ...a, stairs_description: e };
        });
        updateStairs(formData,index);
    }

    const removeTransferStep = (i) => {
        let tmp = formData;
        tmp.transfer_steps.splice(i, 1);
        // // console.log(tmp)
        setFormData((a) => {
            return { ...a, transfer_steps: tmp.transfer_steps };
        });
        updateStairs(formData,index);
    };

    const changeTransferStep = (e, i, type, obj) => {
        //console.log("transfer change", type);
        let tmp = formData;
        let value = e.target.value;
        if (type === "number") {
            //Update number of transfer step
            tmp.transfer_steps[i].number = value;
        } else {
            tmp.transfer_steps[i].type = value;
            //Set default data
            tmp.transfer_steps[i].name = obj.name;
            tmp.transfer_steps[i].price = obj.price;
            tmp.transfer_steps[i].id = obj.id;
        }

        //Update state
        setFormData((a) => {
            return { ...a, transfer_steps: tmp.transfer_steps };
        });
        updateStairs(formData,index);
    };



    const updateObjectNumbers = (field, value) => {
        let tmp = data.numbers;
        // tmp[field] = value;
        if (tmp.find((o, k) => o.name === field)) {
            tmp.map((o, k) => {
                if (o.name == field) {
                    o.value = value;
                }
            });
        } else {
            tmp.push({ name: field, value: value });
        }

        setFormData(tmp);
        updateStairs(formData, variantTab);
    };
    
    

    const updateNumbers = (value, obj, type) => {
        const parsedValue = parseInt(value);
    
        if (type === 'accessoires') {
            setSelectedAccessoires(prevState => {
                const updatedState = prevState.map(a => (a.id === obj.id ? { ...a, number: parsedValue } : a));
                setFormData(prevFormData => ({
                    ...prevFormData,
                    selectedAccessoires: updatedState
                }));
                return updatedState;
            });
        } else {
            setSelectedLabors(prevState => {
                const updatedState = prevState.map(a => (a.id === obj.id ? { ...a, number: parsedValue } : a));
                setFormData(prevFormData => ({
                    ...prevFormData,
                    selectedLabors: updatedState
                }));
                return updatedState;
            });
        }
    };

 
    
    
    useEffect(() => {
        if (data && !sync) {
            changeSelectedMaterial(data.material);
            setSelectedAccessoires(data.selectedAccessoires);
            setSelectedLabors(data.selectedLabors);
            setFormData(data);
            setSync(true);
        }
    }, [data, sync, changeSelectedMaterial]);
    

    const onChangeForm = (e) => {
        let obj = {
            stairs_description: e.target.form.stairs_description ? e.target.form.stairs_description.value : data.stairs_description,
            type: e.target.form.type ? e.target.form.type.value : null,
            model: e.target.form.model ? e.target.form.model.value : null,
            material: e.target.form.material ? e.target.form.material.value : null,
            color_step: e.target.form.color_step ? e.target.form.color_step.value : null,
            color_riser: e.target.form.color_riser ? e.target.form.color_riser.value : null,
            exit_step: e.target.form.exit_step ? e.target.form.exit_step.value : null,
            cheeks: e.target.form.cheeks ? e.target.form.cheeks.value : 'nee',
            color_cheeks: e.target.form.color_cheeks ? e.target.form.color_cheeks.value : null,
            color_l_u_profile: e.target.form.color_l_u_profile ? e.target.form.color_l_u_profile.value : null,
            extra_description: e.target.form.extra_description ? e.target.form.extra_description.value : null,
            selectedAccessoires: selectedAccessoires,
            selectedLabors: selectedLabors,
            selectedMaterial: selectedMaterial,
            transfer_steps: data.transfer_steps,
            numbers: data.numbers,
        };
    
        setFormData(obj);
        updateStairs(obj, index);
    };

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedAccessoires(selectedAccessoires);
            setDebouncedLabors(selectedLabors);
        }, 300); // Adjust the debounce delay as needed
    
        return () => {
            clearTimeout(handler);
        };
    }, [selectedAccessoires, selectedLabors]);
    
    // This effect now triggers only when debounced values change
    useEffect(() => {
        setFormData(prevFormData => ({
            ...prevFormData,
            selectedAccessoires: debouncedAccessoires,
            selectedLabors: debouncedLabors,
        }));
    }, [debouncedAccessoires, debouncedLabors]);
    

    useEffect(() => {
        updateStairs(formData, index);
    }, [formData, index]);

 const changeLabors = (e, o) => {
        let tmp = selectedLabors ? selectedLabors : [];
        let found = false;
        if(tmp){
            tmp.map((a) => {
                if (a.id == o.id) {
                    found = true;
                }
            });
        }
        if (!found) {
            o.number = 1;
            tmp.push(o);
            setSelectedLabors(tmp);
        } else {
            let new_array = tmp.filter((a) => {
                return o.id !== a.id;
            });
            setSelectedLabors(new_array);
        }
    };

    const changeAccessoires = (e, o) => {
        let tmp = selectedAccessoires ? selectedAccessoires : [];
        let found = false;
        if(tmp){
            tmp.map((a) => {
                if (a.id == o.id) {
                    found = true;
                }
            });
        }
        if (!found) {
            o.number = 1;
            tmp.push(o);
            setSelectedAccessoires(tmp);
        } else {
            let new_array = tmp.filter((a) => {
                return o.id !== a.id;
            });
            setSelectedAccessoires(new_array);
        }
    };
    
    

    const exists = (o, d) => {
        let found = false;
        if(d){
            d.map(a => {
                if(a.name == o.name){
                    found = true
                }
            })
        }
        return found
    };

    

    const getNumber = (o, d) => {
        let nr = null;
        if(d){
            d.map(a => {
                if(a.name == o.name){
                    nr = a.number
                }
            })
        }
        return nr
    };





    // useEffect(() => {
    //     console.log(selectedAccessoires, selectedLabors);
    // }, [selectedAccessoires, selectedLabors]);

    return (
        <div key={index+'acc'}>
         <h4 className="fw-bold mt-4" style={headingStyle}>
            <div className="row">
                <div className="col-6">
                   Trap {parseInt(index)+2}
                </div>
                <div className="col-6 text-end">
                    <button className="btn btn-outline-secondary text-secondary btn-sm" onClick={() => removeStairs(index)}>Verwijderen</button>
                </div>
            </div>
            </h4>
            { data &&
             <form
                onSubmit={handleSubmit}
                onChange={(e) => onChangeForm(e)}
                ref={form}
            >
                <div className="row">
                <div className="question-group col-12">
                    <p className="fw-bold mb-0 mt-2">Trap omschrijving</p>
                    <input className="form-control" name="stairs_description" value={data.stairs_description} onChange={(e) => updateStairDescription(e.target.value)} />
                </div>
                <div className={"question-group col-4" + (selectedMaterial !== null ? '' : ' col-12')}>
                        <p className="fw-bold mb-0 mt-2">Type trap</p>
                        <div
                            className="btn-group w-100"
                            role="group"
                            aria-label="Basic radio toggle button group"
                        >
                            
                            <input
                                type="radio"
                                value="no"
                                className="btn-check"
                                name="type"
                                id={"type2"+index+variantTab}
                                autoComplete="off"
                                defaultChecked={data.type == 'no'}
                            />
                            <label
                            className={"btn btn-outline-primary" + (selectedMaterial !== null ? '' : ' btn-lg')} 
                                htmlFor={"type2"+index+variantTab}
                            >
                                Dicht
                            </label>
                            <input
                                type="radio"
                                value="yes"
                                className="btn-check"
                                name="type"
                                id={"type1"+index+variantTab}
                                autoComplete="off"
                                defaultChecked={data.type == 'yes'}
                            />
                            <label
                                className={"btn btn-outline-primary" + (selectedMaterial !== null ? '' : ' btn-lg')} 
                                htmlFor={"type1"+index+variantTab}
                            >
                                Open
                            </label>
                        </div>
                    </div>
                    <div className={"question-group col-4" + (selectedMaterial !== null ? '' : ' col-12')}>
                        <p className="fw-bold mb-0 mt-2">Model</p>
                        <div
                            className="btn-group w-100"
                            role="group"
                            aria-label="Basic radio toggle button group"
                        >
                            <input
                                type="radio"
                                className="btn-check"
                                value="recht"
                                name="model"
                                id={"model1"+index+variantTab}
                                autoComplete="off"
                                defaultChecked={data.model == 'recht'}
                            />
                            <label
                         className={"btn btn-outline-primary" + (selectedMaterial !== null ? '' : ' btn-lg')} 
                                htmlFor={"model1"+index+variantTab}
                            >
                                Recht
                            </label>
                            <input
                                type="radio"
                                className="btn-check"
                                value="enkelkwart"
                                name="model"
                                id={"model2"+index+variantTab}
                                autoComplete="off"
                                defaultChecked={data.model == 'enkelkwart'}
                            />
                            <label
                                  className={"btn btn-outline-primary" + (selectedMaterial !== null ? '' : ' btn-lg')} 
                                htmlFor={"model2"+index+variantTab}
                            >
                                Enkelkwart
                            </label>
                            <input
                                type="radio"
                                className="btn-check"
                                value="dubbelkwart"
                                name="model"
                                id={"model3"+index+variantTab}
                                autoComplete="off"
                                defaultChecked={data.model == 'dubbelkwart'}
                            />
                            <label
                                 className={"btn btn-outline-primary" + (selectedMaterial !== null ? '' : ' btn-lg')} 
                                htmlFor={"model3"+index+variantTab}
                            >
                                Dubbelkwart
                            </label>
                        </div>
                    </div>
                    <div className={"question-group col-4" + (selectedMaterial !== null ? '' : ' col-12')}>
                        <p className="fw-bold mb-0 mt-2">Materiaal</p>
                        <select
                            className="form-control"
                            name="material"
                            value={data.material}
                            onChange={(e) => changeSelectedMaterial(e.target.value)}
                        >
                             <option value="">Selecteer optie</option>
                            {materials.length > 0 &&
                                materials.map((o, k) => {
                                    return (
                                        <option key={k} value={o.id}>
                                            {o.name}
                                        </option>
                                    );
                                })}
                        </select>
                    </div>
                    { selectedMaterial &&
                        <>
                        <div className="question-group col-12">
                                <div className="row">
                            <div className="col-12">
                                <p className="fw-900 fs-4 mb-0 mt-2">
                                    Tredes
                                </p>
                            </div>

                            </div>
                        <p className="fw-bold mb-0 mt-2">Kleur</p>
                        <select className="form-control" name="color_step" value={data.color_step}>
                          <option value="">Selecteer optie</option>
                            {selectedMaterial &&
                                selectedMaterial.colors.map((o, k) => {
                                    return (
                                        <option value={o.id} key={index+k}>
                                            {o.name}
                                        </option>
                                    );
                                })}
                        </select>
                    </div>
                    <div className="question-group col-12">
                        {/* <p className="fw-bold mb-0 mt-2">Overzettredes</p> */}
                        <div className="row">
                            <div className="col-12">
                            <p className="fw-900 fs-4 mb-0 mt-2">
                                    Overzettreden
                                </p>
                            </div>
                            {data &&
                                data.transfer_steps &&
                                data.transfer_steps.map((o, k) => {
                                    return (
                                        <div className="col-12" key={k}>
                                            <div className="row">
                                                <div className="col-7">
                                                    <p className="fw-bold mb-0 mt-2">
                                                        Maat
                                                    </p>
                                                    <select
                                                        className="form-control"
                                                        name="transfer_step"
                                                        value={o.hasOwnProperty('type') ? o.type : ''}
                                                        onChange={(e) =>
                                                            changeTransferStep(
                                                                e,
                                                                k,
                                                                "type",
                                                                {
                                                                    name: e
                                                                        .target
                                                                        .options[
                                                                        e.target
                                                                            .selectedIndex
                                                                    ].dataset
                                                                        .name,
                                                                    price: e
                                                                        .target
                                                                        .options[
                                                                        e.target
                                                                            .selectedIndex
                                                                    ].dataset
                                                                        .price,
                                                                    id: e
                                                                        .target
                                                                        .options[
                                                                        e.target
                                                                            .selectedIndex
                                                                    ].dataset
                                                                        .id,
                                                                }
                                                            )
                                                        }
                                                    >
                                                        <option value="">Selecteer optie</option>
                                                        {selectedMaterial &&
                                                            selectedMaterial.transfersteps.map(
                                                                (o, k) => {
                                                                    return (
                                                                        <option
                                                                            value={
                                                                                o.id
                                                                            }
                                                                            key={
                                                                                k
                                                                            }
                                                                            data-name={
                                                                                o.name
                                                                            }
                                                                            data-price={
                                                                                o.price
                                                                            }
                                                                            data-id={
                                                                                o.id
                                                                            }
                                                                        >
                                                                            {
                                                                                o.name
                                                                            }
                                                                        </option>
                                                                    );
                                                                }
                                                            )}
                                                    </select>
                                                </div>
                                                <div className="col-4">
                                                    <p className="fw-bold mb-0 mt-2">
                                                        Aantal
                                                    </p>
                                                    <input
                                                        type="number" min="1"
                                                        className="form-control"
                                                        name="transfer_step_nr"
                                                        defaultValue={o.hasOwnProperty('number') ? o.number : ''}
                                                        onChange={(e) =>
                                                            changeTransferStep(
                                                                e,
                                                                k,
                                                                "number",
                                                                o
                                                            )
                                                        }
                                                        placeholder="Aantal"
                                                    />
                                                </div>
                                                <div className="col text-center d-flex">
                                                    {k !== 0 && (
                                                        <button
                                                            type="button"
                                                            onClick={() =>
                                                                removeTransferStep(
                                                                    k
                                                                )
                                                            }
                                                            className={
                                                                "btn btn-danger btn-sm align-self-end w-100"
                                                            }
                                                        >
                                                            Verwijder
                                                        </button>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <button
                                    type="button"
                                    className="btn btn-secondary w-100 mt-3"
                                    onClick={(e) => addTransferStep(e)}
                                >
                                    + Overzettrede toevoegen
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="question-group col-12">
                        <div className="row">
                        <div className="col-12">
                                <p className="fw-900 fs-4 mb-0 mt-2">
                                    Stootbord
                                </p>
                            </div>
                            <div className="col-6">
                            <p className="fw-bold mb-0 mt-2">
                                    Type
                                </p>
                                <select
                                    className="form-control"
                                    name="color_riser"
                                    value={data.color_riser}
                                >
                                      <option value="">Selecteer optie</option>
                                    {selectedMaterial &&
                                        selectedMaterial.colorrisers.map(
                                            (o, k) => {
                                                return (
                                                    <option
                                                        value={o.id}
                                                        key={k}
                                                    >
                                                        {o.name}
                                                    </option>
                                                );
                                            }
                                        )}
                                </select>
                            </div>
                            <div className="col-6">
                                <p className="fw-bold mb-0 mt-2">Aantal</p>
                                <input
                                    type="number" min="1"
                                    placeholder="Aantal"
                                    name="color_riser_nr"
                                    className="form-control"
                                    value={data.numbers.length > 0 && data.numbers.find(a => a.name == 'color_riser') ? data.numbers.find(a => a.name == 'color_riser').value : ''}
                                    onChange={(e) =>
                                        updateObjectNumbers(
                                            "color_riser",
                                            e.target.value
                                        )
                                    }
                                />
                            </div>
                        </div>
                    </div>
                    <div className="question-group col-12">
                        <div className="row">
                        <div className="col-12">
                                <p className="fw-900 fs-4 mb-0 mt-2">
                                Uitlooptrede
                                </p>
                            </div>
                            <div className="col-6">
                            <p className="fw-bold mb-0 mt-2">
                                    Type
                                </p>
                                <select
                                    className="form-control"
                                    name="exit_step"
                                    value={data.exit_step}
                                >
                                      <option value="">Selecteer optie</option>
                                    {selectedMaterial &&
                                        selectedMaterial.exitsteps.map(
                                            (o, k) => {
                                                return (
                                                    <option
                                                        value={o.id}
                                                        key={k}
                                                    >
                                                        {o.name}
                                                    </option>
                                                );
                                            }
                                        )}
                                </select>
                            </div>
                            <div className="col-6">
                                <p className="fw-bold mb-0 mt-2">Aantal</p>
                                <input
                                    type="number" min="1"
                                    placeholder="Aantal"
                                    name="exit_step_nr"
                                    className="form-control"
                                    defaultValue={data.numbers.find(a => a.name == 'exit_step') ? data.numbers.find(a => a.name == 'exit_step').value : ''}
                                    onChange={(e) =>
                                        updateObjectNumbers(
                                            "exit_step",
                                            e.target.value
                                        )
                                    }
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                                <p className="fw-900 fs-4 mb-0 mt-2">
                                Wangen
                                </p>
                            </div>
                    <div className="question-group col-12">
                    <p className="fw-bold mb-0 mt-2">Zijn deze gewenst?</p>
                        <div
                            className="btn-group w-100"
                            role="group"
                            aria-label="Basic radio toggle button group"
                        >
                            <input
                                type="radio"
                                className="btn-check"
                                value="ja"
                                name="cheeks"
                                id={"wangen1"+index+variantTab}
                                autoComplete="off"
                                defaultChecked={data.cheeks == 'ja'}

                            />
                            <label
                                className="btn btn-outline-primary"
                                htmlFor={"wangen1"+index+variantTab}
                            >
                                Ja
                            </label>
                            <input
                                type="radio"
                                className="btn-check"
                                value="nee"
                                name="cheeks"
                                id={"wangen2"+index+variantTab}
                                autoComplete="off"
                                defaultChecked={data.cheeks == 'nee' || data.cheeks == ''}
                            />
                            <label
                                className="btn btn-outline-primary"
                                htmlFor={"wangen2"+index+variantTab}
                            >
                                Nee
                            </label>
                        </div>
                    </div>

                    <div
                        className={
                            "question-group col-12 " +
                            (formData.cheeks !== null &&
                            formData.cheeks !== "" &&
                            formData.cheeks !== "nee"
                                ? "d-block"
                                : "d-none")
                        }
                    >
                        <div className="row">
                            <div className="col-6">
                                <p className="fw-bold mb-0 mt-2">
                                    Type
                                </p>
                                <select
                                    className="form-control"
                                    name="color_cheeks"
                                >
                                      <option value="">Selecteer optie</option>
                                    {selectedMaterial &&
                                        selectedMaterial.cheeks.map((o, k) => {
                                            return (
                                                <option value={o.id} key={k}>
                                                    {o.name}
                                                </option>
                                            );
                                        })}
                                </select>
                            </div>
                            <div className="col-6">
                                <p className="fw-bold mb-0 mt-2">Aantal</p>
                                <input
                                    type="number" min="1"
                                    className="form-control"
                                    name="cheeks_nr"
                                    placeholder="Aantal"
                                    onChange={(e) =>
                                        updateObjectNumbers(
                                            "color_cheeks",
                                            e.target.value
                                        )
                                    }
                                />
                            </div>
                        </div>
                    </div>

                    <div className={
                            "question-group col-12 " +
                            (formData.cheeks !== null &&
                            formData.cheeks !== "" &&
                            formData.cheeks !== "nee"
                                ? "d-block"
                                : "d-none")
                        }>
                        <div className="row">
                            <div className="col-6">
                                <p className="fw-bold mb-0 mt-2">
                                    L profiel
                                </p>
                                <select
                                    className="form-control"
                                    name="color_l_u_profile"
                                >
                                      <option value="">Selecteer optie</option>
                                    {selectedMaterial &&
                                        selectedMaterial.landus.map((o, k) => {
                                            return (
                                                <option value={o.id} key={k}>
                                                    {o.name}
                                                </option>
                                            );
                                        })}
                                </select>
                            </div>
                            <div className="col-6">
                                <p className="fw-bold mb-0 mt-2">Aantal</p>
                                <input
                                    type="number" min="1"
                                    className="form-control"
                                    placeholder="Aantal"
                                    name="l_profile_nr"
                                    onChange={(e) =>
                                        updateObjectNumbers(
                                            "l_profile",
                                            e.target.value
                                        )
                                    }
                                />
                            </div>
                        </div>
                    </div>
                    <div className={
                            "question-group col-12 " +
                            (formData.cheeks !== null &&
                            formData.cheeks !== "" &&
                            formData.cheeks !== "nee"
                                ? "d-block"
                                : "d-none")
                        }>
                        <div className="row">
                            <div className="col-6">
                                <p className="fw-bold mb-0 mt-2">
                                    U profiel
                                </p>
                                <select className="form-control" name="">
                                  <option value="">Selecteer optie</option>
                                    {selectedMaterial &&
                                        selectedMaterial.landus.map((o, k) => {
                                            return (
                                                <option value={o.id} key={k}>
                                                    {o.name}
                                                </option>
                                            );
                                        })}
                                </select>
                            </div>
                            <div className="col-6">
                                <p className="fw-bold mb-0 mt-2">Aantal</p>
                                <input
                                    type="number" min="1"
                                    className="form-control"
                                    placeholder="Aantal"
                                    name="u_profile_nr"
                                    onChange={(e) =>
                                        updateObjectNumbers(
                                            "u_profile",
                                            e.target.value
                                        )
                                    }
                                />
                            </div>
                        </div>
                    </div>
                    <div className="question-group col-6">
                        <p className="fw-bold mb-0 mt-2">Accessoires</p>
                        <ul className="list-group">
                            {dataAccessoires.sort((a, b) => a.order - b.order).map((o, k) => {
                                return (
                                    <li className="list-group-item" key={k+index+'acclist'}>
                                        <label>
                                            <input
                                                type="checkbox"
                                                className="me-2"
                                                defaultChecked={exists(
                                                    o,
                                                    selectedAccessoires
                                                )}
                                                onChange={(e) =>
                                                    changeAccessoires(e, o)
                                                }
                                            />
                                            {o.name}
                                        </label>
                                        {exists(o, selectedAccessoires) && (
                                            <div className="form-floating">
                                                <input
                                                    placeholder="Aantal"
                                                    type="number" min="1"
                                                    className="form-control"
                                                    name={'acc'+k+index}
                                                    defaultValue={getNumber(o,selectedAccessoires) !== null ? getNumber(o,selectedAccessoires) : 1}
                                                    onChange={(e) =>
                                                        updateNumbers(
                                                            e.target.value ? e.target.value : 1,
                                                            o,
                                                            "accessoires"
                                                        )
                                                    }
                                                />
                                                <label>Aantal</label>
                                            </div>
                                        )}
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                    <div className="question-group col-6">
                        <p className="fw-bold mb-0 mt-2">Arbeid</p>
                        <ul className="list-group">
                            {dataLabors.sort((a, b) => a.order - b.order).map((o, k) => {
                                return (
                                    <li className="list-group-item" key={k}>
                                        <label>
                                            <input
                                                type="checkbox"
                                                className="me-2"
                                                defaultChecked={exists(
                                                    o,
                                                    selectedLabors
                                                )}
                                                onChange={(e) =>
                                                    changeLabors(e, o)
                                                }
                                            />
                                            {o.name}
                                        </label>
                                        {exists(o, selectedLabors) && (
                                            <div className="form-floating">
                                                <input
                                                    placeholder="Aantal"
                                                    type="number" min="1"
                                                    name={'labor'+k+index}
                                                    className="form-control"
                                                    defaultValue={getNumber(o,selectedLabors) !== null ? getNumber(o,selectedLabors) : 1}
                                                    onChange={(e) =>
                                                        updateNumbers(
                                                            e.target.value ? e.target.value : 1,
                                                            o,
                                                            "labors"
                                                        )
                                                    }
                                                />
                                                <label>Aantal</label>
                                            </div>
                                        )}
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                    <div className="question-group col-12">
                        <p className="fw-900 fs-4 mb-0 mt-2">Overige opmerkingen</p>
                        <textarea className="form-control" name="extra_description" value={data.extra_description} placeholder="Vul hier overige opmerkingen mits van toepassing"></textarea>
                    </div>
                        </>
                    }

                </div>
            </form>
            }
        </div>
    );
};

export default ExtraBasicInformation;
