import React, { useState } from 'react';
import axios from 'axios';

const CustomerCareFormModal = ({ isOpen, closeModal, careId, fetchCustomerCare }) => {
    const [formData, setFormData] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        axios.post('/sales/customer-care/'+careId, { description: formData })
            .then((res) => {
                console.log('Form submitted successfully:', res.data);
                fetchCustomerCare();
                closeModal();
            })
            .catch((err) => {
                console.log('Error submitting form:', err);
            });
    };

    return (
        <div className={`modal-overlay ${isOpen ? 'show' : ''}`} onClick={closeModal}>
            <div className={`modal-content ${isOpen ? 'show' : ''}`} onClick={(e) => e.stopPropagation()}>
                <button className="close-button" onClick={closeModal}>×</button>
                <h3>Customer care afronden</h3>
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <textarea
                            value={formData}
                            onChange={(e) => setFormData(e.target.value)}
                            className="form-control"
                            name='sales_description'
                            rows="5"
                            placeholder="Opmerkingen met betrekking tot gesprek met klant..."
                            required
                        />
                    </div>
                    <button type="submit" className="btn btn-primary w-100 mt-3">Afronden</button>
                </form>
            </div>
        </div>
    );
};

export default CustomerCareFormModal;
