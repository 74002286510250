import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import {
    Horse,
    Heart,
    Cube,
    PlusCircle,
    X,
    Clock,
    ArrowRight,
    CheckCircle,
    Check,
    XCircle,
    MagnifyingGlass,
    Calendar,
} from "phosphor-react";
import ClientCreateForm from "./ClientCreateForm";
import moment from "moment";
import { after } from "lodash";
import { CalendarDots, CalendarHeart, MapPin, Phone } from "@phosphor-icons/react";
import CustomerCare from "./CustomerCare";

moment.locale("nl");

const ClientSelection = (props) => {
    const { client, setClient, planning, goToStep,setBasicInformation,setTab,afterSales,fetchCustomerCare } = props;

    const [search, setSearch] = useState('')
    const [clientUpdated, setClientUpdated] = useState(false); // Flag to track client update


    useEffect(() => {
        if (clientUpdated) {
            setTab([{ name: "Variant name", selected: true }]);
            setBasicInformation([
                {
                    stairs_description: null,
                    type: null,
                    model: null,
                    material: null,
                    color_step: null,
                    color_riser: null,
                    exit_step: null,
                    transfer_step: null,
                    cheeks: 'nee',
                    color_cheeks: null,
                    color_l_u_profile: null,
                    extra_description: null,
                    selectedMaterial: null,
                    transfer_steps: [{}],
                    numbers: [],
                    extra_stairs: []
                }
            ]);
            setClientUpdated(false); // Reset the flag
        }
    }, [clientUpdated]);
    
    
 
    const selectClient = (c) => {
        setClient(c);
        setTab([{ name: "Variant name", selected: true }]);
        setBasicInformation([
            {
                stairs_description: null,
                type: null,
                model: null,
                material: null,
                color_step: null,
                color_riser: null,
                exit_step: null,
                transfer_step: null,
                cheeks: 'nee',
                color_cheeks: null,
                color_l_u_profile: null,
                extra_description: null,
                selectedMaterial: null,
                transfer_steps: [{}],
                numbers: [],
                extra_stairs: []
            }
        ]);
        setClientUpdated(true); // Set the flag to true
    };
    
    const goToQuotationInformation = (o) => {      
        let json = JSON.parse(o.product_rules);
        setBasicInformation([json]);
        goToStep("information");
    };
    
    const goToInformation = (o) => {
        if (o.variants !== null) {
            let json = JSON.parse(o.variants);
            setTab(json.tabs);
    
            const clean_json_data = json.basicInformation.map((c) => {
                return c.hasOwnProperty('data') ? c.data : c;
            });
    
            setBasicInformation(clean_json_data);
        } else {
            setTab([{ name: "Variant name", selected: true }]);
            setBasicInformation((prev) => [
                {
                    stairs_description: null,
                    type: null,
                    model: null,
                    material: null,
                    color_step: null,
                    color_riser: null,
                    exit_step: null,
                    transfer_step: null,
                    cheeks: 'nee',
                    color_cheeks: null,
                    color_l_u_profile: null,
                    extra_description: null,
                    selectedMaterial: null,
                    transfer_steps: [{}],
                    numbers: [],
                    extra_stairs: []
                }
            ]);
        }
    
        goToStep("information");
    };

    const getNiceDateTime = (date) => {
        var local = new Date(date).toLocaleString('nl-NL', { hour12: false });
        return local
    }

   

    return (
        <>
            <div className="accordion mt-4" id="accordionPanelsStayOpenExample">
                <div className="accordion-item">
                    <div
                        className="accordion-header"
                        id="panelsStayOpen-headingOne"
                    >
                        <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#panelsStayOpen-collapseOne"
                            aria-expanded="false"
                            aria-controls="panelsStayOpen-collapseOne"
                        >
                          <CalendarDots size={36}/>  Selecteer afspraak ({planning.length})
                        </button>
                        
                    </div>
                    <div
                        id="panelsStayOpen-collapseOne"
                        className="accordion-collapse collapse"
                        aria-labelledby="panelsStayOpen-headingOne"
                    >
                        <div className="accordion-body p-2">
                            <div className="customer-search position-relative">
                                <MagnifyingGlass weight={'bold'} size={20} color={'#aaa'} className="position-absolute" style={{top:'8px', left:'5px'}}/>
                                <input type="text" className="form-control" style={{paddingLeft:'30px'}} onChange={e => setSearch(e.target.value)} placeholder="Zoek op klantnaam"/>
                            </div>
                            {planning &&
                                planning.length > 0 &&
                                planning.filter(item => {
                                    if(search !== ''){
                                        return item.customer.firstname.toLowerCase().includes(search.toLowerCase()) || item.customer.lastname.toLowerCase().includes(search.toLowerCase());
                                    } else {
                                        return item
                                    }
                                }).map((o, k) => {
                                    return (
                                        <div
                                            className={
                                                "card rounded-2 my-2 customer-card" +
                                                (client && o.id == client.id
                                                    ? " active"
                                                    : "")
                                            }
                                            key={k}
                                        >
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        <p className="mb-0 text-muted small">
                                                            <Clock
                                                                size={16}
                                                                style={{
                                                                    marginTop:
                                                                        "-2px",
                                                                    marginRight:
                                                                        "3px",
                                                                }}
                                                            />
                                                            {moment(
                                                                o.time
                                                            ).format(
                                                                "DD-MM-YYYY HH:MM"
                                                            )}{" "}
                                                            - {o.subject}
                                                        </p>
                                                        <p className="mb-0 fw-bold">
                                                            {
                                                                o.customer
                                                                    .firstname
                                                            }{" "}
                                                            {
                                                                o.customer
                                                                    .lastname
                                                            }
                                                        </p>
                                                        <p className="mb-0 d-flex w-100 align-items-center gap-2"><MapPin fill={'#aaa'} size={16}/>{o.customer.street} {o.customer.number}{o.customer.addition}, {o.customer.zipcode}, {o.customer.place}</p>
                                                        <p className="mb-0 d-flex w-100 align-items-center gap-2"><Phone fill={'#aaa'} size={16}/>{o.customer.phone}</p>                                                        
                                                    </div>
                                                    <div className="col-md-4 text-start">
                                                        <div className="row h-100">
                                                            <div className="col-6 text-center">
                                                                <div className={o.variants !== null ? "quotation-stats w-100 active" : "quotation-stats w-100"}>
                                                                <span className="d-block">Varianten</span>
                                                                { o.variants !== null ?
                                                                 <CheckCircle size={24} weight={'bold'}/>
                                                                 :
                                                                 <XCircle size={24} weight={'bold'}/>     
                                                                }
                                                                </div>
                                                            </div>
                                                            <div className="col-6 text-center">
                                                                <div className={o.quotations.length > 0 ? "quotation-stats w-100 active" : "quotation-stats w-100"}>
                                                                <span className="d-block">Offerte</span>
                                                                { o.quotations.length > 0 ?
                                                                  <CheckCircle size={24} weight={'bold'}/>
                                                                  :
                                                                  <XCircle size={24} weight={'bold'}/>          
                                                                }
                                                                </div>
                                                            </div>
                                                        </div>

                                                    {/* { o.variants !== null &&
                                                            <p style={{color:'green',fontWeight:700, margin:0}}><Check size={18} weight={'bold'}/> Varianten data aanwezig</p>
                                                        }
                                                       { o.quotations.length > 0 &&
                                                            <p style={{color:'green',fontWeight:700, margin:0}}><Check size={18} weight={'bold'}/> Uitgestuurde offerte aanwezig</p>
                                                        } */}
                                                    </div>
                                                    <div className={client && client.id == o.id ? "col-md-4 text-center text-md-end" : "col-md-4 text-center text-md-end d-flex"}>
                                                        <button
                                                            type="button"
                                                            className={client && client.id == o.id ? "btn bg-transparent btn-outline-primary text-primary" : "btn btn-primary align-self-center ms-auto"}
                                                            onClick={() =>
                                                                selectClient(o)
                                                            }
                                                        >
                                                            {client &&
                                                            client.id == o.id
                                                                ? <span><CheckCircle size={14}/> Geselecteerd</span>
                                                                : "Selecteer afspraak"}
                                                        </button>
                                                        
                                                        {client &&
                                                        client.id == o.id ? (
                                                            <>
                                                            <button
                                                                className="btn btn-primary mt-2 btn-lg text-white fw-semibold"
                                                                onClick={() => goToInformation(o)
                                                                    
                                                                }
                                                            >
                                                                { o.variants !== null ?
                                                                    <span>Verder met samenstellen</span>
                                                                    :
                                                                    <span>Nieuwe offerte samenstellen</span>
                                                                }
                                                                
                                                                
                                                                <ArrowRight
                                                                    size={14}
                                                                />
                                                            </button>            
                                                            { o.quotations.length > 0 &&
                                                                    <p className="fw-bold mt-3 mb-0 fs-5 mb-3">Of ga verder met open offertes:</p>
                                                                }
                                                            { o.quotations.length > 0 &&
                                                            
                                                                   o.quotations.sort((b,c) => { return new Date(c.created_at) - new Date(b.created_at);}).map( (a,k) => {
                                                                    return (
                                                                        <button
                                                                   className="btn btn-light d-block mb-2 text-primary fw-semibold w-100"
                                                                   onClick={() => goToQuotationInformation(a,k)                                                                   
                                                                   }
                                                                   key={k}
                                                               >
                                                                       <p className="mb-0 small">Offerte uitgegeven op:</p>
                                                                       <p className="mb-0">{getNiceDateTime(a.created_at)} <ArrowRight
                                                                       size={14}
                                                                   /></p>
                                                                 
                                                                 
                                                                   
                                                                   
                                                               </button>
                                                                    )
                                                                   })                                                                   }     
                                                                
                                                            </>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </div>
                                                    {/* <div className="col-12">
                                                    { o.quotations.length > 0 &&
                                                                <ul className="list-group">
                                                                    { o.quotations.map((o,k) => {
                                                                        return (
                                                                            <li className="list-group-item">
                                                                                {o.created_at}
                                                                            </li>
                                                                        )
                                                                    })

                                                                    }
                                                                </ul>
                                                            }
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                        </div>
                    </div>
                </div>

                <div className="accordion-item">
                    <div
                        className="accordion-header"
                        id="panelsStayOpen-headingOne"
                    >
                        <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#panelsStayOpen-collapseTwo"
                            aria-expanded="false"
                            aria-controls="panelsStayOpen-collapseTwo"
                        >
                           <CalendarHeart size={36}/> Customer Care ({afterSales.length})
                        </button>
                        
                    </div>
                    <div
                        id="panelsStayOpen-collapseTwo"
                        className="accordion-collapse collapse"
                        aria-labelledby="panelsStayOpen-headingTwo"
                    >
                        <div className="accordion-body p-2">
                            { afterSales && afterSales.length > 0 && afterSales.map((o,k) => {

                                return (
                                    <CustomerCare key={k} o={o} fetchCustomerCare={fetchCustomerCare}/>                                    
                                )
                                                        })

                            }
                            </div>
                    
                    </div>
                    </div>

                {/* <div className="accordion-item">
                    <div
                        className="accordion-header"
                        id="panelsStayOpen-headingTwo"
                    >
                        <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#panelsStayOpen-collapseTwo"
                            aria-expanded="true"
                            aria-controls="panelsStayOpen-collapseTwo"
                        >
                            Maak nieuwe klant aan
                        </button>
                    </div>
                    <div
                        id="panelsStayOpen-collapseTwo"
                        className="accordion-collapse collapse"
                        aria-labelledby="panelsStayOpen-headingTwo"
                    >
                        <div className="accordion-body">
                            <ClientCreateForm />
                        </div>
                    </div>
                </div> */}
            </div>
        </>
    );
};

export default ClientSelection;
